import { Modal, Image, Alert, Divider } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserLogged } from '../../reducers/features/auth/auth';

const SettingsPopup = () => {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Check if geolocation permission is not granted
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state !== 'granted') {
                setIsModalOpen(true);
            }
        });
    }, []);

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    if (isModalOpen) {
        return (
            <Modal
                visible={isModalOpen}
                footer={null}
                closable={true}
                centered={true}
                onCancel={handleModalClose}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <img src="/img/cover@2x-1024x768-min-1024x768.jpg" className="img-fluid" />
                        </div>
                        <div className="col-sm-12 mt-4">
                            <h1>Odpado prosi o dostęp do geolokalizacji.</h1>
                            <p className="mb-4">
                                Przyznając dostęp do geolokalizacji w aplikacji Odpado, umożliwiasz korzystanie z jej funkcji, które pomagają utrzymać czystość i porządek w Twojej okolicy.
                            </p>
                            <p>
                                Dzięki geolokalizacji możesz łatwo zlokalizować miejsca wymagające sprzątania oraz otrzymać dokładne informacje na temat problematycznych obszarów wokół Ciebie.
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    } else {
        return null;
    }
};

export default SettingsPopup;
