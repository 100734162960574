
import View from "ol/View";
import Geolocation from "ol/Geolocation";

export default class GeolocationFactory {

    /**
     * 
     */
    initialize() {

        const view = new View({
            center: [0, 0],
            zoom: 2,
          });

        let geolocation = new Geolocation({
            trackingOptions: {
                enableHighAccuracy: true,
            },
            projection: view.getProjection(),
        });

        geolocation.setTracking(true);

        geolocation.on("error", function (error) {
            //console.log(error.message);
        });

        return geolocation;
    }
}