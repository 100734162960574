import { Modal, Image, Alert, Divider } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

const WelcomePopup = () => {
    const identity = useSelector((state) => state.auth);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setIsModalOpen(true);
    }, []);

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    if (isModalOpen) {
        return (
            <Modal
                visible={isModalOpen}
                footer={null}
                closable={true}
                centered={true}
                onCancel={handleModalClose}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <Image src="/img/happy_welcome.jpg" className="fluid-img"></Image>
                        </div>
                        <div className="col-sm-12 mt-4">
                            <h1>{identity.user.shortName}, rozpocznij porządki w swojej okolicy!</h1>

                            <Alert
                                description="W tej okolicy nie ma żadnych aktywności, oznacza to, że w promieniu od 5 do 15 km od Twojej obecnej lokalizacji nikt nie dodał jeszcze nowego miejsca. Warto pamiętać, że nasza aplikacja działa w pełni na podstawie geolokalizacji, dlatego aktywności są widoczne tylko wokół Twojego położenia."
                                type="info"
                                showIcon
                            />

                            <p className='mt-4'>
                                To idealna okazja dla Ciebie, aby być pierwszym użytkownikiem, który oznaczy i posprząta nowe miejsce w Twojej okolicy. Twoje zaangażowanie może stać się inspiracją dla innych użytkowników i zachęcić ich do działania. Będziemy wdzięczni za Twój wkład w rozwijanie naszej społeczności i tworzenie czystszej przestrzeni dla nas wszystkich.
                            </p>
                            <p>
                                Jesteśmy bardzo zadowoleni, że dołączyłeś do naszej społeczności aktywnych użytkowników, którzy razem pracują nad poprawą czystości naszego kraju. W całej Polsce jest wielu użytkowników, którzy aktywnie oznaczają zaśmiecone miejsca i podejmują działania sprzątania. Mamy również tysiące zarejestrowanych miejsc, które zostały już posprzątane dzięki naszej wspólnej pracy.
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    } else {
        return null;
    }
};

export default WelcomePopup;
