import { combineReducers } from 'redux'

import place from './features/place/place';
import user from './features/user/user';
import auth from './features/auth/auth';

const rootReducer = combineReducers({
  place: place,
  user: user,
  auth: auth
})

export default rootReducer