  /**
   *
   * @returns
   */
  export const fetchOpenStreetPlaceData = async (placeCoordinates) => {
    const headers = new Headers();
    headers.append("User-Agent", "odpado.pl - A nature supporting app.");

    const options = {
      headers: headers,
    };

    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=geojson&lat=${placeCoordinates.latitude}&lon=${placeCoordinates.longitude}`,
      options
    );

    if (!response.ok) {
      throw new Error("Data could not be fetched!");
    } else {
      return response.json();
    }
  };