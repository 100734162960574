const initialState = {
    isLogged: false,
    user: {}
};

const ACTIONS = {
    SET_USER_LOGGED: 'userLogged',
    SET_USER_LOGGED_OUT: 'userLoggedOut',
};

export const setUserLogged = (user) => {
    return {
        type: ACTIONS.SET_USER_LOGGED, 
        payload: {
            isLogged: true,
            user: user
        }
      };
}

export const setUserLoggedOut = () => {
    localStorage.removeItem('identity');
    return {
        type: ACTIONS.SET_USER_LOGGED_OUT, 
        payload: {
            isLogged: false,
            user: {}
        }
      };
}

export default function filtersReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.SET_USER_LOGGED: {
            return {
                isLogged: action.payload.isLogged,
                user: action.payload.user
            };
        };       
        case ACTIONS.SET_USER_LOGGED_OUT: {
            return {
                isLogged: action.payload.isLogged,
                user: action.payload.user
            };
        };                
        default:
            return state;
    }
}