import {
  Modal,
  Alert,
  Button,
  Space,
  Empty,
  Tooltip,
  Divider,
  Avatar,
  Tabs,
  Input,
  message,
  List,
  Form,
  Comment,
  Badge,
  Image,
  Tag,
  Switch,
  Spin,
  Card,
  Carousel,
} from "antd";
import {fetchOpenStreetPlaceData} from "../../libs/Geolocation/OpenStreet";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { initializeApp } from "firebase/app";
import { distanceBetweenGeohashes } from "../../libs/Helpers/Geolocation";
import {
  getFirestore,
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import firebaseConfig from "../../config";
import { hidePlace, deletePlace } from "../../reducers/features/place/place";
import Webcam from "react-webcam";
import { dataURLtoBlob } from "../../libs/Helpers/File";
import {
  BellOutlined,
  CameraOutlined,
  ArrowRightOutlined,
  HeartOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";
import { getMessaging, getToken } from "firebase/messaging";
import { setUserLogged } from "../../reducers/features/auth/auth";
const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "environment",
};

const PlacePopup = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const place = useSelector((state) => state.place);
  const identity = useSelector((state) => state.auth.user);
  const user = useSelector((state) => state.user);
  const [coordinates, setCoordinates] = useState({ longitude: 0, latitude: 0 });
  const [placeId, setPlaceId] = useState(null);
  const [comment, setComment] = useState(null);
  const [placeAddress, setPlaceAddress] = useState("");
  const [isPopupUIdisabled, setIsPopupUIdisabled] = useState(false);
  const [placeData, setPlaceData] = useState(false);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
  const [isPictureModeEnabled, setIsPictureModeEnabled] = useState(false);
  const [isCommentSubmitting, setIsCommentSubmitting] = useState(false);
  const [roundedDistance, setRoundedDistance] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const [commentPicture, setCommentPicture] = useState("");
  const [picture, setPicture] = useState("");
  const commentsWebcamRef = React.useRef(null);
  const commentInputRef = React.useRef(null);

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // Initialize Cloud Firestore and get a reference to the service
  const db = getFirestore(app);
  const storage = getStorage();
  const messaging = getMessaging(app);

  /**
   *
   */
  const capture = React.useCallback(() => {
    const pictureSrc = commentsWebcamRef.current.getScreenshot();
    setCommentPicture(pictureSrc);
    messageApi.open({
      type: "success",
      content: "Zdjęcie zostało zrobione i zostanie dołączone do komentarza.",
    });
  });

  /**
   *
   */
  const handlePictureMode = (checked) => {
    setIsPictureModeEnabled(checked);
  };

  const enableNotifications = () => {
    setIsPopupUIdisabled(true);
    // Request permission for push notifications
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // Get the FCM token
        getToken(messaging, {
          vapidKey:
            "BHRR9D2KUuKmLCDZmW8F3OAIT5Lr5P6l7SB2XRIwQQhs2gbgK_HZwqGjPHRYgn8D5BMeOueLMUGxPZANnqvYZSU",
        })
          .then((token) => {
            const userRef = doc(db, "users", identity.id);
            getDoc(userRef).then((docSnap) => {
              if (docSnap.exists()) {
                const docData = docSnap.data();
                if (!docData.fcmTokens.includes(token)) {
                  // Check if token exists in fcmTokens
                  const fcmTokens = [...docData.fcmTokens, token];
                  const fcmSubscribedPlaces = [
                    ...docData.fcmSubscribedPlaces,
                    placeId,
                  ];
                  updateDoc(userRef, {
                    fcmTokens: fcmTokens,
                    fcmSubscribedPlaces: fcmSubscribedPlaces,
                  }).then(() => {
                    setUserLogged(() => ({
                      ...identity,
                      fcmSubscribedPlaces: fcmSubscribedPlaces,
                    }));

                    messageApi.open({
                      type: "success",
                      content: "Powiadomienia włączone.",
                    });
                  });
                } else if (!docData.fcmSubscribedPlaces.includes(placeId)) {
                  // Check if channel is already subscribed
                  const fcmSubscribedPlaces = [
                    ...docData.fcmSubscribedPlaces,
                    placeId,
                  ];
                  updateDoc(userRef, {
                    fcmSubscribedPlaces: fcmSubscribedPlaces,
                  }).then(() => {
                    setUserLogged(() => ({
                      ...identity,
                      fcmSubscribedPlaces: fcmSubscribedPlaces,
                    }));

                    messageApi.open({
                      type: "success",
                      content: "Powiadomienia włączone.",
                    });
                  });
                }
              }
            });
            // Send the token to your server to associate it with the user
          })
          .catch((error) => {
            console.error(error);
          });
      }
      setIsPopupUIdisabled(false);
    });
  };

  /**
   *
   * @param {*} userId
   * @param {*} deviceToken
   * @param {*} topic
   * @returns
   */
  const checkUserSubscription = (userId, deviceToken, topic) => {
    const userRef = doc(db, "users", userId);

    return getDoc(userRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const docData = docSnap.data();
          const isTokenSubscribed = docData.fcmTokens.includes(deviceToken);
          const isTopicSubscribed = docData.fcmSubscribedPlaces.includes(topic);

          return isTokenSubscribed && isTopicSubscribed;
        }
      })
      .catch((error) => {
        console.error("Error checking user subscription:", error);
        return null;
      });
  };

  /**
   *
   */
  const disableNotifications = () => {
    setIsPopupUIdisabled(true);
    const userRef = doc(db, "users", identity.id);
    getDoc(userRef).then((docSnap) => {
      if (docSnap.exists()) {
        const docData = docSnap.data();
        if (docData.fcmSubscribedPlaces.includes(placeId)) {
          // Check if channel is subscribed
          const fcmSubscribedPlaces = docData.fcmSubscribedPlaces.filter(
            (id) => id !== placeId
          ); // Remove placeId from array
          updateDoc(userRef, {
            fcmSubscribedPlaces: fcmSubscribedPlaces,
          }).then(() => {
            setUserLogged(() => ({
              ...identity,
              fcmSubscribedPlaces: fcmSubscribedPlaces,
            }));
            messageApi.open({
              type: "success",
              content: "Powiadomienia wyłączone.",
            });
          });
        }
      }
      setIsPopupUIdisabled(false);
    });
  };

  /**
   * 
   * @param {*} supporterId 
   */
  const deleteSupporter = (supporterId) => {
    setIsPopupUIdisabled(true);
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    const docRef = doc(db, "places", placeId);

    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const doc = docSnap.data();
        const updatedSupporters = doc.supporters.filter(
          (supporter) => supporter.identity.id !== supporterId
        );

        updateDoc(docRef, {
          supporters: updatedSupporters,
        }).then(() => {
          messageApi.open({
            type: "success",
            content: "Zostałeś usunięty z listy pomocników.",
          });
          // Add a new document in collection "places"
          const docRef = addDoc(collection(db, "activities"), {
            docStructVersion: "1.0.0",
            seenBy: [],
            geohash: doc.geohash,
            label: "Użytkownik zrezygnował z pomocy",
            objectType: "place",
            activityType: "supporterResigned",
            object: {
              docStructVersion: "1.0.0",
              id: placeId,
              status: doc.status,
              geohash: doc.geohash,
              description: doc.description,
              coordinates: doc.coordinates,
              tags: doc.tags,
              donation: doc.donation,
              comments: doc.comments,
              label: doc.label,
              picture: doc.picture,
              identity: doc.identity,
              createdAt: {
                timestamp: doc.createdAt.timestamp,
                localeString: doc.createdAt.localeString,
              },
            },
            createdAt: {
              timestamp: Date.now(),
              localeString: new Date().toLocaleString(),
            },
          });
          setPlaceData((prevPlaceData) => ({
            ...prevPlaceData,
            supporters: updatedSupporters,
          }));
          setIsPopupUIdisabled(false);
        });
      }
    });
  };

  /**
   *
   * @param {*} supporterId
   */
  const updateSupporterStatus = (supporterId) => {
    setIsPopupUIdisabled(true);
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    const docRef = doc(db, "places", placeId);

    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const doc = docSnap.data();
        const updatedSupporters = doc.supporters.map((supporter) => {
          if (supporter.identity.id === supporterId) {
            return {
              ...supporter,
              status: "fixed",
            };
          }
          return supporter;
        });

        updateDoc(docRef, {
          supporters: updatedSupporters,
        }).then(() => {
          messageApi.open({
            type: "success",
            content: "Status Twojej pomocy został zaktualizowany. Dziękujemy!",
          });
          // Add a new document in collection "places"
          const docRef = addDoc(collection(db, "activities"), {
            docStructVersion: "1.0.0",
            seenBy: [],
            geohash: doc.geohash,
            label: "Pomocnik pomógł w posprzątaniu miejsca",
            objectType: "place",
            activityType: "supporterFixed",
            object: {
              docStructVersion: "1.0.0",
              id: placeId,
              status: doc.status,
              geohash: doc.geohash,
              description: doc.description,
              coordinates: doc.coordinates,
              tags: doc.tags,
              donation: doc.donation,
              comments: doc.comments,
              label: doc.label,
              picture: doc.picture,
              identity: doc.identity,
              createdAt: {
                timestamp: doc.createdAt.timestamp,
                localeString: doc.createdAt.localeString,
              },
            },
            createdAt: {
              timestamp: Date.now(),
              localeString: new Date().toLocaleString(),
            },
          });
          setPlaceData((prevPlaceData) => ({
            ...prevPlaceData,
            supporters: updatedSupporters,
          }));
          setIsPopupUIdisabled(false);
        });
      }
    });
  };

  /**
   *
   */
  const handleCreateSupporter = () => {
    setIsPopupUIdisabled(true);
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    let docRef = doc(db, "places", placeId);

    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        let doc = docSnap.data();
        // Check if comments field exists, add it if it doesn't
        if (!doc.hasOwnProperty("supporters")) {
          doc.supporters = [];
        }
        const newSupporter = {
          identity: {
            ...identity,
            fcmSubscribedPlaces: null, // Remove fcmSubscribedPlaces field
          },
          status: "new",
          createdAt: {
            timestamp: Date.now(),
            localeString: new Date().toLocaleString(),
          },
        };
        const supporters = [...doc.supporters, newSupporter];
        updateDoc(docRef, {
          supporters: supporters,
        }).then(() => {
          // Add a new document in collection "places"
          const docRef = addDoc(collection(db, "activities"), {
            docStructVersion: "1.0.0",
            seenBy: [],
            geohash: doc.geohash,
            label: "Użytkownik wyraził chęć pomocy",
            objectType: "place",
            activityType: "newSupporter",
            object: {
              docStructVersion: "1.0.0",
              id: placeId,
              status: doc.status,
              geohash: doc.geohash,
              description: doc.description,
              coordinates: doc.coordinates,
              tags: doc.tags,
              donation: doc.donation,
              comments: doc.comments,
              label: doc.label,
              picture: doc.picture,
              identity: doc.identity,
              createdAt: {
                timestamp: doc.createdAt.timestamp,
                localeString: doc.createdAt.localeString,
              },
            },
            createdAt: {
              timestamp: Date.now(),
              localeString: new Date().toLocaleString(),
            },
          });
          setPlaceData((prevPlaceData) => ({
            ...prevPlaceData,
            supporters: supporters,
          }));
          setIsPopupUIdisabled(false);
        });
      }
    });
  };

  /**
   *
   */
  const handleEnableNotifications = (checked) => {
    if (checked === true) {
      enableNotifications();
    } else {
      disableNotifications();
    }
    setIsNotificationEnabled(checked);
  };

  /**
   *
   */
  const handleCommentSubmit = () => {
    setIsPopupUIdisabled(true);
    setIsCommentSubmitting(true);
    setIsPictureModeEnabled(false);
    let comment = commentInputRef.current.resizableTextArea.props.value;

    if (!comment && !commentPicture) {
      setIsCommentSubmitting(false);
      setIsPopupUIdisabled(false);
      messageApi.open({
        type: "error",
        content: "Komentarz musi posiadać treść lub/i zdjęcie.",
      });
      return;
    }

    if (place.feature.getId()) {
      let docRef = doc(db, "places", place.feature.getId());
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          let doc = docSnap.data();
          // Check if comments field exists, add it if it doesn't
          if (!doc.hasOwnProperty("comments")) {
            doc.comments = [];
          }

          if (commentPicture) {
            // Add new comment to the existing comments array
            const storageRef = ref(
              storage,
              `place/${place.feature.getId()}/comments/${Date.now()}.jpeg`
            );

            // Create file metadata including the content type
            /** @type {any} */
            const metadata = {
              contentType: "image/jpeg",
            };

            const blob = dataURLtoBlob(commentPicture);
            const uploadTask = uploadBytesResumable(storageRef, blob, metadata);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (progress == 100) {
                  setCommentPicture("");
                  messageApi.open({
                    type: "success",
                    content: "Zdjęcie zostało dodane na serwer.",
                  });
                }
              },
              (error) => {
                // Handle unsuccessful uploads
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  const newComment = {
                    comment: comment,
                    identity: identity,
                    picture: downloadURL,
                    createdAt: {
                      timestamp: Date.now(),
                      localeString: new Date().toLocaleString(),
                    },
                  };
                  const comments = [...doc.comments, newComment];
                  updateDoc(docRef, {
                    comments: comments,
                  }).then(() => {
                    setIsCommentSubmitting(false);
                    setPlaceData((prevPlaceData) => ({
                      ...prevPlaceData,
                      comments: comments,
                    }));
                    setIsPopupUIdisabled(false);
                  });
                });
              }
            );
          } else {
            const newComment = {
              comment: comment,
              identity: identity,
              picture: "",
              createdAt: {
                timestamp: Date.now(),
                localeString: new Date().toLocaleString(),
              },
            };
            const comments = [...doc.comments, newComment];
            updateDoc(docRef, {
              comments: comments,
            }).then(() => {
              // Add a new document in collection "places"
              const docRef = addDoc(collection(db, "activities"), {
                docStructVersion: "1.0.0",
                seenBy: [],
                geohash: doc.geohash,
                label: "Nowa wypowiedź w dyskusji",
                objectType: "place",
                activityType: "newComment",
                object: {
                  id: placeId,
                  docStructVersion: "1.0.0",
                  status: doc.status,
                  geohash: doc.geohash,
                  description: doc.description,
                  coordinates: doc.coordinates,
                  tags: doc.tags,
                  donation: doc.donation,
                  comments: doc.comments,
                  label: doc.label,
                  picture: doc.picture,
                  identity: doc.identity,
                  createdAt: {
                    timestamp: doc.createdAt.timestamp,
                    localeString: doc.createdAt.localeString,
                  },
                },
                createdAt: {
                  timestamp: Date.now(),
                  localeString: new Date().toLocaleString(),
                },
              });
              setIsCommentSubmitting(false);
              setPlaceData((prevPlaceData) => ({
                ...prevPlaceData,
                comments: comments,
              }));
              setIsPopupUIdisabled(false);
            });
          }
        }
      });
    }
  };

  /**
   *
   * @param {*} param0
   * @returns
   */
  const CommentList = ({ comments }) => {
    let commentsAdapter = new Array();

    for (const comment of comments) {
      commentsAdapter.push({
        avatar: comment.identity.photoUrl,
        content: comment.comment,
        datetime: <span>{comment.createdAt.localeString}</span>,
        author: comment.identity.shortName,
        picture: comment.picture ? (
          <div>
            <Image className="img-fluid mt-2 shadow" src={comment.picture} />
          </div>
        ) : null,
      });
    }

    commentsAdapter.reverse();

    return (
      <List
        dataSource={commentsAdapter}
        header={false}
        itemLayout="horizontal"
        renderItem={(props) => (
          <Comment
            {...props}
            content={
              <>
                {props.content}
                {props.picture}
              </>
            }
          />
        )}
      />
    );
  };

  /**
   *
   * @param {*} param0
   * @returns
   */
  const Editor = ({ onSubmit, isCommentSubmitting }) => (
    <>
      <Form.Item>
        <TextArea
          placeholder="Napisz komentarz..."
          rows={4}
          ref={commentInputRef}
        />
      </Form.Item>
      <Form.Item>
        <Switch
          checkedChildren={<CameraOutlined />}
          unCheckedChildren={<CameraOutlined />}
          checked={isPictureModeEnabled}
          onChange={handlePictureMode}
        ></Switch>
        &nbsp;&nbsp;
        <Button
          htmlType="submit"
          loading={isCommentSubmitting}
          onClick={onSubmit}
          type="primary"
        >
          Dodaj komentarz
        </Button>
      </Form.Item>
    </>
  );

  useEffect(() => {
    setIsModalOpen(place.showPopup);
    setPlaceId(place.feature.getId());
    if (place.showPopup === true && place.feature.getId()) {
      getDoc(doc(db, "places", place.feature.getId())).then((docSnap) => {
        if (docSnap.exists()) {
          let doc = docSnap.data();
          if (!doc.hasOwnProperty("supporters")) {
            doc.supporters = [];
          }
          setPlaceData(doc);
          fetchOpenStreetPlaceData(doc.coordinates).then((data) =>
            setPlaceAddress(data.features[0].properties.display_name)
          );

          // Request permission for push notifications
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              // Get the FCM token
              getToken(messaging, {
                vapidKey:
                  "BHRR9D2KUuKmLCDZmW8F3OAIT5Lr5P6l7SB2XRIwQQhs2gbgK_HZwqGjPHRYgn8D5BMeOueLMUGxPZANnqvYZSU",
              }).then((token) => {
                console.log(identity.id, token, place.feature.getId());
                checkUserSubscription(identity.id, token, place.feature.getId())
                  .then((subscriptionStatus) => {
                    setIsNotificationEnabled(subscriptionStatus);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              });
            }
          });

          let coordinates = doc.coordinates;
          const distance = distanceBetweenGeohashes(
            user.coordinates,
            coordinates
          );
          const roundedDistance = parseFloat(distance.toFixed(2));
          setRoundedDistance(roundedDistance);

          messageApi.open({
            type: "success",
            content: `Pobrałem listę komentarzy ${doc.comments.length}.`,
          });
        }
      });
    }
  }, [place.showPopup]);

  /**
   *
   */
  const deleteDirectory = async (directoryPath) => {
    try {
      // Get a reference to the directory
      const directoryRef = ref(storage, directoryPath);

      // List all items (files) in the directory
      const items = await listAll(directoryRef);

      // Delete all items (files) in the directory
      await Promise.all(
        items.items.map(async (item) => {
          await deleteObject(item);
        })
      );
    } catch (error) {}
  };

  /**
   *
   */
  const handleDeletePlace = () => {
    setIsPopupUIdisabled(true);
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    deleteDoc(doc(db, "places", place.feature.getId())).then(() => {
      // Create a reference to the directory
      deleteDirectory(`place/${place.feature.getId()}/comments`);
      dispatch(deletePlace(place.coordinates, place.feature, place.map));

      // Add a new document in collection "places"
      const docRef = addDoc(collection(db, "activities"), {
        docStructVersion: "1.0.0",
        seenBy: [],
        geohash: placeData.geohash,
        label: "Oflagowane miejsce zostało usunięte z mapy",
        objectType: "place",
        activityType: "placeDeleted",
        object: {
          docStructVersion: "1.0.0",
          id: placeId,
          status: placeData.status,
          geohash: placeData.geohash,
          description: placeData.description,
          coordinates: placeData.coordinates,
          tags: placeData.tags,
          donation: placeData.donation,
          comments: placeData.comments,
          label: placeData.label,
          picture: placeData.picture,
          identity: placeData.identity,
          createdAt: {
            timestamp: placeData.createdAt.timestamp,
            localeString: placeData.createdAt.localeString,
          },
        },
        createdAt: {
          timestamp: Date.now(),
          localeString: new Date().toLocaleString(),
        },
      });
      setIsPopupUIdisabled(false);
    });
  };

  /**
   *
   */
  const handleFixedPlace = () => {
    setIsPopupUIdisabled(true);
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    let docRef = doc(db, "places", placeId);
    updateDoc(docRef, {
      status: "fixed",
    }).then(() => {
      messageApi.open({
        type: "success",
        content:
          "Miejsce oznaczone jako posprzątane i już nie będzie pojawiać się na mapie. Dziękujęmy w imieniu lokalnej społeczności!",
      });
      // Add a new document in collection "places"
      const docRef = addDoc(collection(db, "activities"), {
        docStructVersion: "1.0.0",
        seenBy: [],
        geohash: placeData.geohash,
        label: "Oflagowane miejsce zostało posprzątane",
        objectType: "place",
        activityType: "placeFixed",
        object: {
          docStructVersion: "1.0.0",
          id: placeId,
          status: placeData.status,
          geohash: placeData.geohash,
          description: placeData.description,
          coordinates: placeData.coordinates,
          tags: placeData.tags,
          donation: placeData.donation,
          comments: placeData.comments,
          label: placeData.label,
          picture: placeData.picture,
          identity: placeData.identity,
          createdAt: {
            timestamp: placeData.createdAt.timestamp,
            localeString: placeData.createdAt.localeString,
          },
        },
        createdAt: {
          timestamp: Date.now(),
          localeString: new Date().toLocaleString(),
        },
      });
      setPlaceData((prevPlaceData) => ({
        ...prevPlaceData,
        status: "fixed",
      }));
      setIsPopupUIdisabled(false);
      dispatch(deletePlace(place.coordinates, place.feature, place.map));
    });
  };

  /**
   *
   */
  const handleCancel = () => {
    dispatch(hidePlace(place.coordinates, place.feature, place.map));
    message.destroy();
  };

  if (isModalOpen) {
    if (placeData === false) {
      return <>{contextHolder}</>;
    }

    return (
      <>
        {contextHolder}
        <Modal
          title={placeData.label}
          visible={isModalOpen}
          footer={(null, null)}
          onCancel={handleCancel}
          centered={true}
        >
          <Spin spinning={isPopupUIdisabled} delay={500}>
            <div className="container m-0 p-0">
              <div className="row">
                <div className="col-10 small text-muted">
                  <Avatar src={placeData.identity.photoUrl}></Avatar>{" "}
                  {placeData.identity.shortName},{" "}
                  {placeData.createdAt.localeString}
                </div>
                <div className="col-2 text-end">
                  <Tooltip
                    placement="bottom"
                    title="Otrzymuj powiadomienia związane z tym zgłoszeniem"
                    visible={false}
                  >
                    <Switch
                      checkedChildren={<BellOutlined />}
                      unCheckedChildren={<BellOutlined />}
                      onChange={handleEnableNotifications}
                      checked={isNotificationEnabled}
                    />
                  </Tooltip>
                </div>
              </div>

              {Array.isArray(placeData.supporters) &&
                !placeData.supporters.some(
                  (supporter) => supporter.identity.id === identity.id
                ) && (
                  <>
                    <div className="col-12 mt-4 pb-5">
                      <Tooltip
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        title="Zadeklaruj swoją chęć pomocy w posprzątaniu tego miejsca."
                        zIndex={1000}
                        placement="bottom"
                        visible={true}
                      >
                        <Button
                          onClick={handleCreateSupporter}
                          style={{ width: "100%" }}
                          type="primary"
                          icon={<HeartOutlined />}
                        >
                          Chcę pomóc
                        </Button>
                      </Tooltip>
                    </div>
                  </>
                )}
            </div>

            <Tabs className="mt-5">
              <TabPane tab="Informacje" key="2">
                <Carousel>
                  <div className="px-2">
                    <Alert
                      message={`Miejsce w odległości ${
                        roundedDistance < 1
                          ? Math.round(roundedDistance * 1000) + " metrów"
                          : roundedDistance.toString().replace(".", ",") + " km"
                      } od Twojego położenia.`}
                      banner
                    />
                    {placeData.tags.length > 0 ? (
                      <div className="my-2">
                        <>
                          <span
                            style={{
                              marginRight: 8,
                            }}
                          >
                            Rodzaj śmieci:
                          </span>
                          {placeData.tags.map((tag) => (
                            <Tag key={tag}>{tag}</Tag>
                          ))}
                        </>
                      </div>
                    ) : (
                      ""
                    )}

                    {placeData.donation ? (
                      <Alert
                        message="Miejsce dotowane"
                        description="Zgłaszający przeznaczył dotację na posprzątanie tego miejsca."
                        type="info"
                        showIcon
                      />
                    ) : (
                      ""
                    )}

                    {placeData.picture ? (
                      <div className="my-2">
                        <img
                          src={placeData.picture}
                          className="shadow"
                          style={{ width: "100%" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {placeData.comments.length > 0 && (
                      <>
                        <Space size={15}>
                          {placeData.comments.map((comment) => {
                            return (
                              comment.picture !== "" && (
                                <span key={comment.createdAt.timestamp}>
                                  <Tooltip
                                    title={comment.createdAt.localeString}
                                    zIndex={1000}
                                    visible={false}
                                  >
                                    <Image width={100} src={comment.picture} />
                                  </Tooltip>
                                </span>
                              )
                            );
                          })}
                        </Space>
                      </>
                    )}
                    {placeData.donation ? (
                      <Divider orientation="left" plain>
                        Zasady
                      </Divider>
                    ) : (
                      ""
                    )}
                    {placeData.donation ? (
                      <p className="mt-2 small">
                        Za posprzątanie tego miejsca otrzymasz{" "}
                        {placeData.donation} PLN płatne czekiem BLIK. Aby
                        otrzymać wynagrodzenie zrób zdjęcia tego miejsca zaraz
                        po przybyciu oraz po skończonej pracy. Zdjęcia dodaj
                        jako komentarze. Przed rozpocząciem sprzątania
                        skontaktuj się z osobą, która dodała to miejsce i ustal
                        w jaki sposób przekaże Tobie czek BLIK.
                      </p>
                    ) : (
                      ""
                    )}

                    {placeData.description ? (
                      <p>{placeData.description}</p>
                    ) : (
                      <Empty description="Zgłaszający nie podał dodatkowych informacji dotyczących tego miejsca." />
                    )}
                  </div>
                  <div className="px-2">
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Alert
                        message={`Aby ustalić adres na podstawie zgłoszonych współrzędnych, korzystamy z naszej bazy danych adresów. Chociaż staramy się zapewnić dokładne adresy, czasami mogą się zdarzyć nieścisłości.`}
                      />
                      <Card title={<span>Automatycznie ustalony adres zgłoszenia</span>}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          {placeAddress}
                        </Space>
                      </Card>
                    </Space>
                  </div>
                </Carousel>
              </TabPane>
              <TabPane
                tab={
                  <Badge status="warning" count={placeData.supporters.length}>
                    Pomocnicy
                  </Badge>
                }
                key="5"
              >
                <Space
                  direction="vertical"
                  size={15}
                  style={{ display: "flex" }}
                >
                  <Alert
                    description="Pomocnicy to osoby, które zadeklarowały chęć pomocy przy posprzątaniu tego miejsca. Pomocnicy działają razem lub niezależnie i dokumentują swoją pracę dodając zdjęcia w sekcji Dyskusja."
                    type="info"
                    showIcon
                  />

                  {placeData.supporters.length == 0 ? (
                    <Empty description="Jako pierwszy zaproponuj pomoc w posprzątaniu tego miejsca." />
                  ) : (
                    ""
                  )}

                  <div className="container">
                    <div className="row align-items-center">
                      {placeData.supporters.map((supporter) => (
                        <React.Fragment key={supporter.identity.id}>
                          <div className="col-12 mb-2">
                            <Space>
                              <Avatar
                                size="large"
                                src={supporter.identity.photoUrl}
                              ></Avatar>
                              {supporter.identity.shortName}{" "}
                              {supporter.status === "new"
                                ? "wyraził chęć pomocy"
                                : "już pomógł"}{" "}
                              <HeartOutlined />
                            </Space>
                          </div>

                          {supporter.identity.id === identity.id &&
                            supporter.status === "new" && (
                              <>
                                <div className="col-6 mt-2">
                                  <Button
                                    onClick={() =>
                                      deleteSupporter(supporter.identity.id)
                                    }
                                    key={`cancel-${supporter.identity.id}`}
                                    type="secondary"
                                    size="small"
                                    style={{ width: "100%" }}
                                  >
                                    Rezygnuję
                                  </Button>
                                </div>
                                <div className="col-6 mt-2">
                                  <Button
                                    onClick={() =>
                                      updateSupporterStatus(
                                        supporter.identity.id
                                      )
                                    }
                                    key={`success-${supporter.identity.id}`}
                                    icon={<HeartOutlined />}
                                    type="primary"
                                    style={{ width: "100%" }}
                                    size="small"
                                  >
                                    Pomogłem
                                  </Button>
                                </div>
                              </>
                            )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </Space>
              </TabPane>
              <TabPane
                tab={
                  <Badge status="warning" count={placeData.comments.length}>
                    Dyskusja
                  </Badge>
                }
                key="3"
              >
                <Space
                  direction="vertical"
                  size={15}
                  style={{ display: "flex" }}
                >
                  <Alert description="Sekcja służy do wymiany informacji oraz dokumentowania własnej pracy zdjęciami, które możesz wykonywać wprost z tego miejsca." />

                  {placeData.comments.length == 0 ? (
                    <Empty description="Dodaj pierwszy komentarz. Pamiętaj o kulturze wypowiedzi." />
                  ) : (
                    ""
                  )}
                  <div className="mt-2">
                    <div
                      className={isPictureModeEnabled ? "d-block" : "d-none"}
                    >
                      <Space
                        direction="vertical"
                        size={15}
                        style={{ display: "flex" }}
                      >
                        <Alert
                          message="Jak zrobić poprawne zdjęcie?"
                          description="Zdjęcie zostanie dołączone do komentarza. Staraj się zrobić zdjęcie, które pomoże w ocenie zgłoszonego miejsca. Chroń prywatność i nie przedstawiaj elementów, które mogłby być rozważone jako element naruszenia czyjejś prywatności."
                          type="info"
                          showIcon
                        />

                        <div className="p-2 bg-dark text-center">
                          {isPictureModeEnabled === true ? (
                            <>
                              {commentPicture == "" ? (
                                <Webcam
                                  audio={false}
                                  height={310}
                                  ref={commentsWebcamRef}
                                  width={310}
                                  screenshotFormat="image/jpeg"
                                  videoConstraints={videoConstraints}
                                />
                              ) : (
                                <img src={commentPicture} />
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        {commentPicture != "" ? (
                          <>
                            <div
                              className="text-center"
                              style={{ marginTop: "-79px" }}
                            >
                              <Button
                                type="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCommentPicture("");
                                }}
                              >
                                Nowe zdjęcie
                              </Button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="text-center"
                              style={{ marginTop: "-79px" }}
                            >
                              <Button
                                type="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  capture();
                                }}
                              >
                                Zrób zdjęcie
                              </Button>
                            </div>
                          </>
                        )}
                      </Space>
                    </div>
                  </div>
                  <Comment
                    avatar={
                      <Avatar
                        src={identity.photoUrl}
                        alt={identity.shortName}
                      />
                    }
                    content={
                      <Editor
                        onSubmit={handleCommentSubmit}
                        isCommentSubmitting={isCommentSubmitting}
                      />
                    }
                  />
                  {placeData.comments.length > 0 && (
                    <CommentList comments={placeData.comments} />
                  )}
                </Space>
              </TabPane>

              {identity.id == placeData.identity.id ? (
                <>
                  <TabPane tab="Status zgłoszenia" key="4">
                    <Space
                      direction="vertical"
                      size={15}
                      style={{ display: "flex" }}
                    >
                      <Alert
                        message="Teren został już posprzątany?"
                        description="Jeśli tak zamknij zgłoszenie."
                        type="info"
                        action={
                          <Button type="primary" onClick={handleFixedPlace}>
                            Zamykam
                          </Button>
                        }
                        showIcon
                      />

                      <Divider orientation="left" plain></Divider>

                      <Button type="danger" onClick={handleDeletePlace}>
                        Usuń
                      </Button>
                    </Space>
                  </TabPane>
                </>
              ) : (
                ""
              )}
            </Tabs>
          </Spin>
        </Modal>
      </>
    );
  }
};
export default PlacePopup;
