import React, { useState, useEffect } from "react";
import { fetchOpenStreetPlaceData } from "../../libs/Geolocation/OpenStreet";
import companies from "../../data/companies.json";
import {
  Drawer,
  Button,
  Card,
  Space,
  Select,
  Input,
  Tooltip,
  message,
  Alert,
  InputNumber,
  Radio,
  Tag,
} from "antd";
import { geohashForLocation } from "geofire-common";
import firebaseConfig from "../../config";
import {
  PushpinOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import {
  createPlace,
  placeCanceled,
  placeCreated,
} from "../../reducers/features/place/place";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import Webcam from "react-webcam";

const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "environment",
};

const NewPlaceForm = () => {
  const dispatch = useDispatch();

  const identity = useSelector((state) => state.auth);
  const [isNewPlaceFormVisible, setIsNewPlaceFormVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [label, setLabel] = useState(null);
  const [company, setCompany] = useState(false);
  const [description, setDescription] = useState(null);
  const [donation, setDonation] = useState(0);
  const [
    isNewPlaceCreatedByUserLocalization,
    setIsNewPlaceCreatedByUserLocalization,
  ] = useState(false);

  const { TextArea } = Input;
  const { TabPane } = Tabs;

  const place = useSelector((state) => state.place);
  const user = useSelector((state) => state.user);
  const tagsData = [
    "Szkło",
    "Plastik",
    "Papier",
    "Złom",
    "Sprzęt RTV/AGD",
    "Odpady budowlane",
    "Odzież",
    "Zalanie",
    "Pozostałe"
  ];
  const { CheckableTag } = Tag;
  const [selectedTags, setSelectedTags] = useState([]);
  const [picture, setPicture] = useState("");
  const webcamRef = React.useRef(null);

  let handleDontaionValue = (value) => {
    setDonation(value);
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Cloud Firestore and get a reference to the service
  const db = getFirestore(app);

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    messageApi.open({
      type: "success",
      content: "Zdjęcie zostało zrobione.",
    });
  });

  function findCompayByMunicipality(name, records) {
    for (let i = 0; i < records.length; i++) {
      const includeOnly = records[i].includeOnly;
      if (includeOnly && includeOnly.includes(name)) {
        return records[i];
      }
    }
    return null; // Return null if no matching record is found
  }

  useEffect(() => {
    setIsNewPlaceFormVisible(place.showNewPlaceForm);
    let data = fetchOpenStreetPlaceData(user.coordinates).then((data) => {
      const foundRecord = findCompayByMunicipality(
        data.features[0].properties.address.municipality,
        companies
      );
      if (foundRecord) {
        setCompany(foundRecord);
      } else {
        setCompany(false);
      }
    });
  }, [place.showNewPlaceForm]);

  useEffect(() => {
    setIsNewPlaceCreatedByUserLocalization(false);
  }, [user.coordinates]);

  const handleTagsChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  /**
   *
   */
  const removeTemporaryFeature = () => {
    const source = place.map.placesLayer.getSource();
    const feature = source.getFeatureById(place.feature.getId());
    if (feature) {
      source.removeFeature(feature);
    }
  };

  /**
   *
   */
  const showNewPlaceForm = () => {
    let coordinates = user.coordinates;
    let map = user.map;
    let feature = new Feature({
      geometry: new Point(fromLonLat(coordinates)),
      label: "Nowy punkt (niezapisany)",
    });
    map.placesLayer.getSource().addFeature(feature);
    feature.setId(9999); // All unsaved features get 9999
    dispatch(createPlace(coordinates, feature, map));
    setIsNewPlaceCreatedByUserLocalization(true);
  };

  /**
   *
   */
  const hideNewPlaceForm = () => {
    let coordinates = place.coordinates;
    let map = place.map;
    let feature = new Feature({
      geometry: new Point(fromLonLat(coordinates)),
      label: "Nowy punkt (niezapisany)",
    });
    map.placesLayer.getSource().addFeature(feature);
    feature.setId(9999); // All unsaved features get 9999
    dispatch(placeCreated(coordinates, feature, map));
  };

  /**
   *
   * @returns
   */
  const onSave = () => {
    if (!label) {
      messageApi.open({
        type: "error",
        content: "Uzupełnij kategorię zgłoszenia.",
      });
      return;
    }

    if (selectedTags.length == 0) {
      messageApi.open({
        type: "error",
        content: "Wybierz przynajmniej jeden rodzaj śmieci.",
      });
      return;
    }

    let coordinates = place.coordinates;

    if (isNewPlaceCreatedByUserLocalization === true) {
      coordinates = user.coordinates;
    }

    console.log(
      "isNewPlaceCreatedByUserLocalization = " +
        isNewPlaceCreatedByUserLocalization
    );
    console.log(
      "coordinates = " + [coordinates.latitude, coordinates.longitude]
    );

    const geohash = geohashForLocation([
      coordinates.latitude,
      coordinates.longitude,
    ]);

    // Add a new document in collection "places"
    const docRef = addDoc(collection(db, "places"), {
      docStructVersion: "1.0.0",
      status: "new",
      geohash: geohash,
      description: description,
      coordinates: coordinates,
      tags: selectedTags,
      donation: donation,
      comments: [],
      label: label,
      picture: picture,
      identity: {
        ...identity.user,
        fcmSubscribedPlaces: null, // Remove fcmSubscribedPlaces field
      },
      createdAt: {
        timestamp: Date.now(),
        localeString: new Date().toLocaleString(),
      },
    });

    docRef
      .then((doc) => {
        setPicture("");
        messageApi.open({
          type: "success",
          content: "Nowe miejsce zostało dodane do mapy. Dziękujemy!",
        });

        // Add a new document in collection "places"
        const docRef = addDoc(collection(db, "activities"), {
          docStructVersion: "1.0.0",
          seenBy: [],
          geohash: geohash,
          label: "Nowe miejsce zostało oflagowane i wymaga posprzątania",
          objectType: "place",
          activityType: "placeCreated",
          object: {
            docStructVersion: "1.0.0",
            id: doc.id,
            status: "new",
            geohash: geohash,
            description: description,
            coordinates: coordinates,
            tags: selectedTags,
            donation: donation,
            comments: [],
            label: label,
            picture: picture,
            identity: {
              ...identity.user,
              fcmSubscribedPlaces: null, // Remove fcmSubscribedPlaces field
            },
            createdAt: {
              timestamp: Date.now(),
              localeString: new Date().toLocaleString(),
            },
          },
          createdAt: {
            timestamp: Date.now(),
            localeString: new Date().toLocaleString(),
          },
        });

        removeTemporaryFeature();
        hideNewPlaceForm();
      })
      .catch((error) => {
        console.error("Error writing data: ", error);
      });
  };

  const onCancel = () => {
    removeTemporaryFeature();
    setPicture("");
    let coordinates = place.coordinates;
    let map = place.map;
    let feature = new Feature({
      geometry: new Point(fromLonLat(coordinates)),
      label: "Nowy punkt (niezapisany)",
    });
    map.placesLayer.getSource().addFeature(feature);
    feature.setId(9999); // All unsaved features get 9999
    dispatch(placeCanceled(coordinates, feature, map));
  };

  return (
    <>
      {contextHolder}
      <Drawer
        placement="bottom"
        width={100}
        onClose={onCancel}
        visible={isNewPlaceFormVisible}
        size="large"
        extra={
          <Space>
            <Button onClick={onCancel}>Anuluj</Button>
            <Button onClick={onSave} type="primary">
              Zapisz
            </Button>
          </Space>
        }
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Podstawowe informacje" key="1">
            <Space direction="vertical" size={15} style={{ display: "flex" }}>
              {company ? (
                <Alert
                  showIcon
                  className=""
                  message={`Twoje zgłoszenie zostanie dodane do mapy oraz przekazane do ${company.name}. ${company.name} wspiera nasze działa w utrzymaniu porządku w Twojej okolicy.`}
                  type="warning"
                />
              ) : null}

              <Select
                onChange={(value) => setLabel(value)}
                style={{ width: "100%" }}
                placeholder="Kategoria zgłoszenia"
              >
                <Select.Option value="Dzikie wysypisko">
                  Dzikie wysypisko
                </Select.Option>
                <Select.Option value="Przepełniony kosz">
                  Przepełniony kosz
                </Select.Option>
                <Select.Option value="Zanieczyszczone pobocze">
                  Zanieczyszczone pobocze
                </Select.Option>
                <Select.Option value="Odchody zwięrząt">
                  Odchody zwięrząt
                </Select.Option>
                <Select.Option value="Wystawka">
                  Wystawka sprawnych/przydatnych rzeczy
                </Select.Option>
              </Select>

              <Card>
                <>
                  <span
                    style={{
                      marginRight: 8,
                    }}
                  >
                    Wybierz rodzaj śmieci:
                  </span>
                  {tagsData.map((tag) => (
                    <CheckableTag
                      key={tag}
                      checked={selectedTags.indexOf(tag) > -1}
                      onChange={(checked) => handleTagsChange(tag, checked)}
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </>
              </Card>

              <label>Dodatkowe informacje</label>
              <Alert
                className=""
                message="Jeśli miejsce wymaga np. odpowiedniego ubioru np. z powodu owadów, daj o tym znać."
                type="info"
              />
              <TextArea
                onInput={(e) => setDescription(e.target.value)}
                rows={4}
                placeholder="Podaj rodzaj śmieci np. opakowania plastikowe, butelki, złom"
                maxLength={255}
              />
            </Space>
          </TabPane>
          <TabPane tab="Zdjęcie" key="2">
            <Space direction="vertical" size={15} style={{ display: "flex" }}>
              <Alert
                message="Jak zrobić poprawne zdjęcie?"
                description="Zrób zdjęcie, które pomoże w ocenie zgłoszonego miejsca. Nie przedstawiaj elementów, które mogłby być rozważone jako element naruszenia prywatności."
                type="info"
                showIcon
              />

              <div className="p-2 bg-dark text-center">
                {picture == "" ? (
                  <Webcam
                    audio={false}
                    height={320}
                    ref={webcamRef}
                    width={320}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                ) : (
                  <img src={picture} />
                )}
              </div>
              <div className="text-center" style={{ marginTop: "-79px" }}>
                {picture != "" ? (
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setPicture("");
                    }}
                  >
                    Nowe zdjęcie
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                    }}
                  >
                    Zrób zdjęcie
                  </Button>
                )}
              </div>
            </Space>
          </TabPane>
          <TabPane tab="Przekaż dotację" key="3">
            <Space direction="vertical" size={15} style={{ display: "flex" }}>
              <Radio.Group
                value={donation}
                onChange={(e) => handleDontaionValue(e.target.value)}
              >
                <Radio.Button value="5">5 PLN</Radio.Button>
                <Radio.Button value="10">10 PLN</Radio.Button>
                <Radio.Button value="20">20 PLN</Radio.Button>
                <Radio.Button value="40">40 PLN</Radio.Button>
                <Radio.Button value="50">50 PLN</Radio.Button>
                <Radio.Button value="80">80 PLN</Radio.Button>
                <Radio.Button value="100">100 PLN</Radio.Button>
              </Radio.Group>

              <Alert
                className=""
                message="Zaoferuj wynagrodzenie za uprzątnięcie tego miejsca. Posługujemy sie czekami BLIK, który należy przekazać sprzątającemu po wykonaniu pracy. Aby zweryfikować czy miejsce zostało uprzątnięte możesz poprosić o dodanie zdjęć w komentarzach do tego miejsca lub udać się osobiście."
                type="info"
              />
            </Space>
          </TabPane>
        </Tabs>
      </Drawer>
      <Button
        type="primary"
        size="large"
        shape="circle"
        icon={<PlusCircleOutlined />}
        onClick={showNewPlaceForm}
      />
    </>
  );
};

export default NewPlaceForm;
