/**
 * 
 * @param {*} userCoordinates 
 * @param {*} placeCoordinates 
 * @returns 
 */
export const distanceBetweenGeohashes = (userCoordinates, placeCoordinates) => {
    // Extract latitude and longitude values
    const lat1 = userCoordinates.latitude;
    const lon1 = userCoordinates.longitude;
    const lat2 = placeCoordinates.latitude;
    const lon2 = placeCoordinates.longitude;

    // Earth's radius in kilometers
    const earthRadius = 6371;

    // Convert degrees to radians
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    // Haversine formula
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Calculate the distance
    const distance = earthRadius * c;

    return distance;
  };

  // Helper function to convert degrees to radians
  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };