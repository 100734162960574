import { Modal, Image, Alert, Divider, Space } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FacebookLoginButton from "../User/FacebookLoginButton";
import AnonymousLoginButton from "../User/AnonymousLoginButton";
import { useDispatch } from "react-redux";
import { setUserLogged } from "../../reducers/features/auth/auth";

const LoginPopup = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const identity = useSelector((state) => state.auth);
  useEffect(() => {
    if (identity.isLogged === false) {
      if (localStorage.getItem("identity") !== null) {
        let identityObject = JSON.parse(localStorage.getItem("identity"));
        dispatch(setUserLogged(identityObject));
      } else {
        setIsModalOpen(true);
      }
    } else {
      setIsModalOpen(false);
    }
  }, [identity.isLogged]);

  if (isModalOpen) {
    return (
      <>
        <Modal
          visible={isModalOpen}
          footer={(null, null)}
          closable={false}
          centered={true}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Image src="/img/login.jpg" className="fluid-img"></Image>
              </div>
              <div className="col-sm-12 mt-4">
                <h1>Odpado pomaga dbać o czystość w Twojej okolicy</h1>
                <p>
                <Space direction="vertical" style={{width: "100%"}}>
                  <AnonymousLoginButton />
                  </Space>

                  <div className="mt-4 mb-4 small">
                    Nie wymagamy podawania żadnych danych aby korzystać z aplikacji. Czuj się tutaj bezpiecznie. Więcej informacji znajdziesz
                    w naszej{" "}
                    <a
                      className="text-muted"
                      href="https://odpado.pl/aplikacja/polityka-prywatnosci"
                    >
                      <u>Polityce Prywatności</u>
                    </a>
                    .
                  </div>

                  <div className="mt-4">
                    <a
                      className="text-muted"
                      href="https://odpado.pl/aplikacja"
                    >
                      <u>Więcej o aplikacji Odpado</u>
                    </a>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
};
export default LoginPopup;
