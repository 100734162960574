import React, { Component } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Button,
  Avatar,
  Alert,
  Divider,
  message,
  Space,
  Badge,
} from "antd";
import { useDispatch } from "react-redux";
import { setUserLoggedOut } from "../../reducers/features/auth/auth";
import { Navigate } from "react-router-dom";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { distanceBetweenGeohashes } from "../../libs/Helpers/Geolocation";
import { setUserLogged } from "../../reducers/features/auth/auth";
import { geohashForLocation } from "geofire-common";
import {
  getFirestore,
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import firebaseConfig from "../../config";
import { BellOutlined, PushpinOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

const Me = () => {
  const identity = useSelector((state) => state.auth.user);
  const user = useSelector((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();
  const [subscribedAreasCounter, setSubscribedAreasCounter] = useState(0);
  const [me, setMe] = useState(false);
  const dispatch = useDispatch();

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const messaging = getMessaging(app);

  const handleLogout = () => {
    dispatch(setUserLoggedOut());
    return <Navigate to="/" replace={true} />;
  };

  const handleSubscribeToPlace = () => {
    if (me.isPremium == false) {
      messageApi.open({
        type: "error",
        content:
          "Tylko konto Premium może subskrybować aktywności w obszarach.",
      });
      return false;
    }

    // Request permission for push notifications
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        let area = {
          coordinates: {
            longitude: user.coordinates.longitude,
            latitude: user.coordinates.latitude,
          },
          geohash: geohashForLocation([
            user.coordinates.latitude,
            user.coordinates.longitude,
          ]),
          radius: 15000,
        };

        // Get the FCM token
        getToken(messaging, {
          vapidKey:
            "BHRR9D2KUuKmLCDZmW8F3OAIT5Lr5P6l7SB2XRIwQQhs2gbgK_HZwqGjPHRYgn8D5BMeOueLMUGxPZANnqvYZSU",
        })
          .then((token) => {
            const userRef = doc(db, "users", identity.id);
            getDoc(userRef).then((docSnap) => {
              if (docSnap.exists()) {
                const docData = docSnap.data();
                const fcmTokens = docData.fcmTokens || []; // Check if fcmTokens exists, otherwise initialize as an empty array
                const fcmSubscribedAreas = docData.fcmSubscribedAreas || []; // Check if fcmSubscribedAreas exists, otherwise initialize as an empty array

                if (!fcmTokens.includes(token)) {
                  // Check if token exists in fcmTokens
                  const updatedFcmTokens = [...fcmTokens, token];
                  const updatedFcmSubscribedAreas = [
                    ...fcmSubscribedAreas,
                    area,
                  ];
                  updateDoc(userRef, {
                    fcmTokens: updatedFcmTokens,
                    fcmSubscribedAreas: updatedFcmSubscribedAreas,
                  }).then(() => {
                    setUserLogged(() => ({
                      ...identity,
                      fcmSubscribedAreas: updatedFcmSubscribedAreas,
                    }));

                    messageApi.open({
                      type: "success",
                      content: "Powiadomienia włączone.",
                    });
                  });
                } else if (
                  !fcmSubscribedAreas.some(
                    (area) => area.geohash === area.geohash
                  )
                ) {
                  // Check if channel is already subscribed
                  const updatedFcmSubscribedAreas = [
                    ...fcmSubscribedAreas,
                    area,
                  ];
                  updateDoc(userRef, {
                    fcmSubscribedAreas: updatedFcmSubscribedAreas,
                  }).then(() => {
                    setUserLogged(() => ({
                      ...identity,
                      fcmSubscribedAreas: updatedFcmSubscribedAreas,
                    }));

                    messageApi.open({
                      type: "success",
                      content:
                        "Powiadomienia o aktywnościach w promieniu 15 km włączone.",
                    });
                  });

                  setSubscribedAreasCounter(updatedFcmSubscribedAreas.length);
                } else {
                  messageApi.open({
                    type: "error",
                    content: "Dla tego obszaru powiadomienia się już włączone.",
                  });
                }
              }
            });
            // Send the token to your server to associate it with the user
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  useEffect(() => {
    const userRef = doc(db, "users", identity.id);
    getDoc(userRef).then((docSnap) => {
      if (docSnap.exists()) {
        const docData = docSnap.data();
        // Your code logic here
        setSubscribedAreasCounter(docData.fcmSubscribedAreas.length);
        setMe(docData);
      }
    });
  }, [subscribedAreasCounter]);

  const handleRemoveAllSubscribedAreas = () => {
    // Get the user reference
    const userRef = doc(db, "users", identity.id);

    // Update the user document to remove all subscribed areas
    updateDoc(userRef, {
      fcmSubscribedAreas: [],
    })
      .then(() => {
        // Update the user state
        setUserLogged((prevUser) => ({
          ...prevUser,
          fcmSubscribedAreas: [],
        }));

        setSubscribedAreasCounter(0);

        // Show success message
        messageApi.open({
          type: "success",
          content: "Wszystkie subskrybowane obszary usunięte.",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (me === false) {
    return <>{contextHolder}</>;
  }

  return (
    <>
      {contextHolder}
      <div className="container p-4 py-5 my-5">
        <div className="row">
          <div className="col">
            <Alert
              message="Jakie informacje posiada o Tobie Odpado.pl?"
              description="Przechowujemy tylko te dane, które faktycznie są potrzebne do prawidłowego działania aplikacji tj. lokalizacja Twojego urządzenia i tylko w przypadku jeśli nadasz uprawnienia aplikacji. Absolutnie nic poza tym."
              type="warning"
              showIcon
            />

            <Card className="mt-4">
              <span className="text-muted">Rodzaj konta</span>{" "}
              {me.isPremium ? "Premium" : "Darmowe"}
            </Card>

            <Card className="mt-4">
              <p>
                <span className="text-muted">Imię i nazwisko</span>{" "}
                {identity.displayName}
              </p>
              <p>
                <span className="text-muted">Zdjęcie profilowe</span>{" "}
                <Avatar src={identity.photoUrl}></Avatar>
              </p>

              <p className="mt-2">
                <span className="text-muted">
                  <PushpinOutlined /> Bieżąca lokalizacja
                </span>{" "}
                {user.coordinates.latitude}, {user.coordinates.longitude}{" "}
              </p>

              <Divider></Divider>
              <Button
                className="float-end d-block"
                type="secondary"
                onClick={handleLogout}
              >
                Wyloguj
              </Button>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Me;
