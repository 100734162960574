import MapFactory from "../../../libs/Map/MapFactory";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";

const initialState = {
    coordinates: { longitude: 0, latitude: 0 },
    feature: new Feature({
        geometry: new Point(
          fromLonLat({ longitude: 0, latitude: 0 })
        ),
        label: "Nowy punkt (niezapisany)"
      }),
    map: (new MapFactory()),
    showNewPlaceForm: false,
    showPopup: false,
    isNewPlace: false,
    isPlaceDeleted: false
};

const ACTIONS = {
    CREATE_PLACE: 'createPlace',
    PLACE_CREATED: 'placeCreated',
    PLACE_CANCELED: 'placeCanceled',
    SHOW_PLACE: 'showPlace',
    HIDE_PLACE: 'hidePlace',
    DELETE_PLACE: 'deletePlace'
};

export const createPlace = (placeCoordinates, featureInstance, mapInstance) => {
    return {
        type: ACTIONS.CREATE_PLACE, 
        payload: {
            coordinates: { longitude: placeCoordinates[0], latitude: placeCoordinates[1] },
            feature: featureInstance,
            map: mapInstance,
            showNewPlaceForm: true,
            showPopup: false,
            isNewPlace: false,
            isPlaceDeleted: false
        }
      };
}

export const placeCanceled = (placeCoordinates, featureInstance, mapInstance) => {
    return {
        type: ACTIONS.PLACE_CANCELED, 
        payload: {
            coordinates: { longitude: placeCoordinates[0], latitude: placeCoordinates[1] },
            feature: featureInstance,
            map: mapInstance,
            showNewPlaceForm: false,
            showPopup: false,
            isNewPlace: false,
            isPlaceDeleted: false
        }
      };
}

export const placeCreated = (placeCoordinates, featureInstance, mapInstance) => {
    return {
        type: ACTIONS.PLACE_CREATED, 
        payload: {
            coordinates: { longitude: placeCoordinates[0], latitude: placeCoordinates[1] },
            feature: featureInstance,
            map: mapInstance,
            showNewPlaceForm: false,
            showPopup: false,
            isNewPlace: true,
            isPlaceDeleted: false
        }
      };
}

export const showPlace = (placeCoordinates, featureInstance, mapInstance) => {
    return {
        type: ACTIONS.SHOW_PLACE, 
        payload: {
            coordinates: { longitude: placeCoordinates[0], latitude: placeCoordinates[1] },
            feature: featureInstance,
            map: mapInstance,
            showNewPlaceForm: false,
            showPopup: true,
            isNewPlace: false,
            isPlaceDeleted: false
        }
      };
}

export const deletePlace = (placeCoordinates, featureInstance, mapInstance) => {
    return {
        type: ACTIONS.DELETE_PLACE, 
        payload: {
            coordinates: { longitude: placeCoordinates[0], latitude: placeCoordinates[1] },
            feature: featureInstance,
            map: mapInstance,
            showNewPlaceForm: false,
            showPopup: false,
            isNewPlace: false,
            isPlaceDeleted: true
        }
      };
}

export const hidePlace = (placeCoordinates, featureInstance, mapInstance) => {
    return {
        type: ACTIONS.HIDE_PLACE, 
        payload: {
            coordinates: { longitude: placeCoordinates[0], latitude: placeCoordinates[1] },
            feature: featureInstance,
            map: mapInstance,
            showNewPlaceForm: false,
            showPopup: false,
            isNewPlace: false,
            isPlaceDeleted: false
        }
      };
}

const placeStateStructure = (state, payload) => {
    return {
        ...state,
        coordinates: payload.coordinates,
        feature: payload.feature,
        map: payload.map,
        showNewPlaceForm: payload.showNewPlaceForm,
        showPopup: payload.showPopup,
        isNewPlace: payload.isNewPlace,
        isPlaceDeleted: payload.isPlaceDeleted
    };
}

export default function filtersReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.CREATE_PLACE: {
            return placeStateStructure(state, action.payload)
        };      
        case ACTIONS.PLACE_CANCELED: {
            return placeStateStructure(state, action.payload)
        };  
        case ACTIONS.PLACE_CREATED: {
            return placeStateStructure(state, action.payload)
        };                      
        case ACTIONS.SHOW_PLACE: {
            return placeStateStructure(state, action.payload)
        };
        case ACTIONS.HIDE_PLACE: {
            return placeStateStructure(state, action.payload)
        };        
        case ACTIONS.DELETE_PLACE: {
            return placeStateStructure(state, action.payload)
        };                
        default:
            return state;
    }
}