import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Overlay } from "ol";
import data from "polskie-miejscowosci";
import {
  Slider,
  message,
  Badge,
  Spin,
  Switch,
  Radio,
  Button,
  Tooltip,
  Image as AntImage,
  Avatar,
  Space,
  Select,
} from "antd";
import { distanceBetweenGeohashes } from "../../libs/Helpers/Geolocation";
import addNotification from "react-push-notification";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import React, { useEffect, useRef } from "react";
import { geohashQueryBounds, distanceBetween } from "geofire-common";
import { fromLonLat, transform } from "ol/proj";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../config";
import GeolocationFactory from "../../libs/Geolocation/GeolocationFactory";
import MapFactory from "../../libs/Map/MapFactory";
import { useDispatch, useSelector } from "react-redux";
import PlacePopup from "../PlacePopup";
import {
  AimOutlined,
  CompassOutlined,
  FlagOutlined,
  MehOutlined,
  CheckOutlined,
  SmileOutlined,
  HeartOutlined,
  DeleteOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import {
  getFirestore,
  query,
  orderBy,
  startAt,
  endAt,
  collection,
  getCountFromServer,
  getDocs,
  limit,
  where,
} from "firebase/firestore";
import { useState } from "react";
import {
  setUserCoordinates,
  resetUserCoordinates,
} from "../../reducers/features/user/user";
import { createPlace, showPlace } from "../../reducers/features/place/place";
import WelcomePopup from "../WelcomePopup/WelcomePopup";
import SettingsPopup from "../SettingsPopup/SettingsPopup";

const Map = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useSelector((state) => state.user);
  const place = useSelector((state) => state.place);
  const identity = useSelector((state) => state.auth);
  const [places, setPlaces] = useState([]);
  const [isInitalDataFetch, setIsInitalDataFetch] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isSliderDisabled, setIsSliderDisabled] = useState(false);
  const [radius, setRadius] = useState(5);
  const [showFixedPlaceCounter, setShowFixedPlaceCounter] = useState(true);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [mostActiveByPlaceCreated, setMostActiveByPlaceCreated] = useState([]);
  const [
    showMostActiveByPlaceCreated,
    setShowMostActiveByPlaceCreated,
  ] = useState(false);

  const [fixedPlacesCounter, setFixedPlacesCounter] = useState(0);
  const [isCenterMyPositionEnabled, setIsCenterMyPositionEnabled] = useState(
    false
  );
  const [zoom, setZoom] = useState(17);
  const [showOnlyDonatedPlaces, setShowOnlyDonatedPlaces] = useState(false);
  const [isLocationSetManually, setIsLocationSetManually] = useState(false);
  const [map, setMap] = useState(new MapFactory());
  const [currentIndex, setCurrentIndex] = useState(0);
  const overlayRef = useRef(null);


  const convertCitiesFormat = (data) => {
    return data.map(obj => ({
      value: `${obj.Longitude},${obj.Latitude}`,
      label: `${obj.Name}, ${obj.Province}`
    }));
  };

  
  const onCityChange = (data) => {
    let coordinates = data.split(',');
    setIsLocationSetManually(true);
    setIsInitalDataFetch(false);
    dispatch(setUserCoordinates(coordinates, map));
  };

  let handleDonatedPlacesFilter = (checked) => {
    setShowOnlyDonatedPlaces(checked);
  };

  let handleCenterMyPosition = (checked) => {
    setIsCenterMyPositionEnabled(checked);
    if (checked === true) {
      messageApi.open({
        type: "success",
        content: `Centrowanie Twojej pozycji na mapie włączone.`,
      });
    } else {
      messageApi.open({
        type: "success",
        content: `Centrowanie Twojej pozycji na mapie wyłączone.`,
      });
    }
  };

  /**
   *
   * @param {*} value
   */
  let handleRadiusChange = (value) => {
    if (user.isLocalized === false) {
      messageApi.open({
        type: "error",
        content: `Aplikacja nie mogła pobrać Twojej obecnej lokalizacji. Spróbuj ponownie.`,
      });
      return;
    }
    setRadius(value);
    setIsSliderDisabled(true);
    fetchPlaces(value);
  };

  /**
   *
   */
  let handleFetchPlaces = () => {
    if (user.isLocalized === false) {
      return;
    }
    setRadius(5);
    setIsSliderDisabled(true);
    fetchPlaces(radius);
  };

  /**
   *
   */
  useEffect(() => {
    if (place.isNewPlace === true || place.isPlaceDeleted === true) {
      handleFetchPlaces();
    }
  }, [place.isNewPlace, place.isPlaceDeleted]);

  /**
   *
   */
  useEffect(() => {
    if (
      showOnlyDonatedPlaces === true &&
      isInitalDataFetch === false &&
      identity.isLogged === true
    ) {
      handleFetchPlaces();
      handleFindMe();
      setIsInitalDataFetch(true);
    }
  }, [showOnlyDonatedPlaces, isInitalDataFetch, identity.isLogged]);

  useEffect(() => {
    getMostActiveUsersByCreatedPlaces();
  }, [places]);

  /**
   *
   */
  useEffect(() => {
    if (
      user.isLocalized === true &&
      isInitalDataFetch === false &&
      identity.isLogged === true
    ) {
      handleFetchPlaces();
      setIsInitalDataFetch(true);
      return () => {
        setTimeout(() => {
          if (showFixedPlaceCounter === true) {
            setShowFixedPlaceCounter(false);
          }
          setShowMostActiveByPlaceCreated(true);
          setTimeout(() => {
            if (showMostActiveByPlaceCreated === true) {
              setShowMostActiveByPlaceCreated(false);
            }
          }, 15000);
        }, 10000);
        handleFindMe();
      };
    }
  }, [user.isLocalized, isInitalDataFetch, identity.isLogged]);

  /**
   *
   */
  const getMostActiveUsersByCreatedPlaces = () => {
    let identities = [];
    if (places.length > 0) {
      const userCount = {};

      // Count the number of Places created by each user
      places.forEach((place) => {
        const identity = place.get("identity");
        const userId = identity.id;
        if (userCount[userId]) {
          userCount[userId].counter += 1;
        } else {
          userCount[userId] = { counter: 1, identity };
        }
      });

      // Create an array of user objects with ID, count, and identity
      identities = Object.values(userCount);
    }

    setMostActiveByPlaceCreated(identities);
  };

  /**
   *
   * @param {*} radius
   */
  let fetchPlaces = async (radius) => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);

    const center = [user.coordinates.latitude, user.coordinates.longitude];
    const radiusInM = radius * 1000;

    const bounds = geohashQueryBounds(center, radiusInM);
    const matchingDocs = [];
    let fixedPlacesTempCounter = 0;
    let q = "";
    for (const b of bounds) {
      if (showOnlyDonatedPlaces === false) {
        q = query(
          collection(db, "places"),
          where("status", "==", "new"),
          orderBy("geohash"),
          limit(100),
          startAt(b[0]),
          endAt(b[1])
        );
      } else {
        q = query(
          collection(db, "places"),
          where("status", "==", "new"),
          where("donation", "in", ["5", "10", "20", "40", "50", "80", "100"]),
          orderBy("geohash"),
          limit(100),
          startAt(b[0]),
          endAt(b[1])
        );
      }

      const querySnapshot = await getDocs(q);

      let qCount = query(
        collection(db, "places"),
        where("status", "==", "fixed"),
        orderBy("geohash"),
        startAt(b[0]),
        endAt(b[1])
      );

      const snapshot = await getCountFromServer(qCount);
      fixedPlacesTempCounter = fixedPlacesTempCounter + snapshot.data().count;

      querySnapshot.forEach((doc) => {
        let coordinates = doc.get("coordinates");
        const lat = coordinates.latitude;
        const lng = coordinates.longitude;

        // We have to filter out a few false positives due to GeoHash
        // accuracy, but most will match
        const distanceInKm = distanceBetween([lat, lng], center);
        const distanceInM = distanceInKm * 1000;
        if (distanceInM <= radiusInM) {
          matchingDocs.push(doc);
        }
      });
    }

    if (matchingDocs.length == 0) {
      // For now dont show the popup
      //setShowWelcomePopup(true);
    }

    setFixedPlacesCounter(fixedPlacesTempCounter);
    setPlaces(matchingDocs);
    setIsSliderDisabled(false);
    fetchActivities(radius);

    messageApi.open({
      type: "success",
      content: `${matchingDocs.length} miejsca w promieniu ${radius}km od obecnego położenia.`,
    });
  };

  /**
   *
   * @param {*} radius
   */
  let fetchActivities = async (radius) => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);

    const center = [user.coordinates.latitude, user.coordinates.longitude];
    const radiusInM = radius * 1000;

    const bounds = geohashQueryBounds(center, radiusInM);
    const matchingActivities = [];
    let q = "";
    for (const b of bounds) {
      q = query(
        collection(db, "activities"),
        orderBy("geohash", "asc"),
        orderBy("createdAt.timestamp", "desc"),
        startAt(b[0]),
        endAt(b[1]),
        limit(40)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        matchingActivities.push(doc);
      });
    }

    setActivities(matchingActivities);

    messageApi.open({
      type: "success",
      content: `${matchingActivities.length} aktywności w promieniu ${radius}km od obecnego położenia.`,
    });
  };

  const showNextPlace = () => {
    if (places.length === 0) {
      messageApi.open({
        type: "error",
        content: `Wskaż zasięg poszukiwań aby zobaczyć miejsca na mapie.`,
      });
      return;
    }
    setShowFixedPlaceCounter(false);
    setShowMostActiveByPlaceCreated(false);

    map.map.getView().setZoom(17.5);
    setZoom(17.5);

    if (currentIndex === places.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }

    const sortedPlaces = places.slice().sort((a, b) => {
      const distanceA = distanceBetweenGeohashes(
        user.coordinates,
        a.get("coordinates")
      );
      const distanceB = distanceBetweenGeohashes(
        user.coordinates,
        b.get("coordinates")
      );
      return distanceA - distanceB;
    });

    const newPlace = sortedPlaces[currentIndex];

    let coordinates = newPlace.get("coordinates");
    const distance = distanceBetweenGeohashes(user.coordinates, coordinates);
    const roundedDistance = parseFloat(distance.toFixed(2));

    let supporters = newPlace.get("supporters") || [];

    let supportersExist = `Pomóż jako pierwszy!`;
    if (supporters.length > 0) {
      supportersExist = `Są też inni chętni do pomocy!`;
    }

    if (roundedDistance < 1) {
      const distanceInMeters = Math.round(roundedDistance * 1000);

      messageApi.open({
        type: "success",
        content: `Miejsce, które czeka na Twoją pomoc. Tylko ${distanceInMeters} metrów od Ciebie. ${supportersExist}`,
      });
    } else {
      const distanceInKilometers = roundedDistance.toString().replace(".", ",");

      messageApi.open({
        type: "success",
        content: `Miejsce, które czeka na Twoją pomoc. Tylko ${distanceInKilometers} km od Ciebie. ${supportersExist}`,
      });
    }

    const adjustedLatitude = coordinates.latitude - 0.0007;
    const adjustedCenter = fromLonLat([
      coordinates.longitude,
      adjustedLatitude,
    ]);

    map.map.getView().setCenter(adjustedCenter);
  };

  let geolocation = new GeolocationFactory();
  geolocation = geolocation.initialize();

  /**
   *
   */
  const handlePositionChange = function() {
    let coordinates = transform(
      geolocation.getPosition(),
      "EPSG:3857",
      "EPSG:4326"
    );
    dispatch(setUserCoordinates(coordinates, map));
  };

  const handleFindMe = () => {
    if (user.isLocalized === false) {
      messageApi.open({
        type: "error",
        content: `Aplikacja nie mogła pobrać Twojej obecnej lokalizacji. Spróbuj ponownie.`,
      });
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const coords = urlParams.get("coords");
    if (coords) {
      const [latitude, longitude] = coords.split(",");
      const center = [parseFloat(longitude), parseFloat(latitude)];
      const adjustedCenter = fromLonLat(center);
      map.map.getView().setCenter(adjustedCenter);
      map.map.getView().setZoom(17.5);
      setZoom(17.5);
      messageApi.open({
        type: "success",
        content: `Zostałeś przekierowany do punktu na mapie.`,
      });
    } else {
      const adjustedCenter = fromLonLat([
        user.coordinates.longitude,
        user.coordinates.latitude,
      ]);

      map.map.getView().setCenter(adjustedCenter);
      messageApi.open({
        type: "success",
        content: `Jesteś tutaj.`,
      });
    }
  };

  /**
   *
   * @param {*} evt
   * @returns
   */
  const handleManualLocalizationClick = function(evt) {
    let coordinates;
    if (user.isLocalized === false && isLocationSetManually === false) {
      setIsLocationSetManually(true);
      setIsInitalDataFetch(false);
      coordinates = transform(evt.coordinate, "EPSG:3857", "EPSG:4326");
      dispatch(setUserCoordinates(coordinates, map));
      handleClick(evt);
      return null;
    }
  };

  /**
   *
   * @param {*} evt
   * @returns
   */
  const handleClick = function(evt) {
    let coordinates;

    if (user.isLocalized === false) {
      return null;
    }

    // Existing feature overlay photo
    const featureId = evt.target.featureId;
    if (featureId) {
      coordinates = {
        longitude: evt.target.coordinates.longitude,
        latitude: evt.target.coordinates.latitude,
      };
      let feature = createFeatureFromCoordinates(coordinates);
      feature.setId(featureId);
      dispatch(showPlace(coordinates, feature, map));
      return;
    }

    // Existing feature icon
    const existingFeature = map.map.forEachFeatureAtPixel(evt.pixel, function(
      feature
    ) {
      return feature;
    });

    coordinates = transform(evt.coordinate, "EPSG:3857", "EPSG:4326");

    if (existingFeature) {
      dispatch(showPlace(coordinates, existingFeature, map));
      return;
    }

    // New feature
    let feature = createFeatureFromCoordinates(coordinates);
    map.placesLayer.getSource().addFeature(feature);
    feature.setId(9999); // All unsaved features get 9999
    dispatch(createPlace(coordinates, feature, map));
  };

  const createFeatureFromCoordinates = function(coordinates) {
    return new Feature({
      geometry: new Point(
        fromLonLat([coordinates.longitude, coordinates.latitude])
      ),
      label: "Nowy punkt (niezapisany)",
    });
  };

  useEffect(() => {
    if (user.isLocalized === false) {
      document.getElementById("map").innerHTML = "";
      map.initialize([19.1203094, 50.8118195]);
      map.map.getView().setZoom(6);
      setZoom(6);
    } else {
      document.getElementById("map").innerHTML = "";
      map.initialize([user.coordinates.longitude, user.coordinates.latitude]);
    }
    map.map.getView().on("change:resolution", () => {
      //setZoom(map.map.getView().getZoom());
    });
  }, [user.isLocalized]);

  useEffect(() => {
    if (isLocationSetManually === true) {
      console.log("render");
      const linkElement = document.createElement("a");
      linkElement.href = "#";
      linkElement.className = "ant-btn ant-btn-sm ant-btn-secondary";
      linkElement.textContent = "✕";
      linkElement.addEventListener("click", () => {
        map.userPositionLayer.getSource().clear();
        map.placesLayer.getSource().clear();
        map.donatedPlacesLayer.getSource().clear();
        map.reusedPlacesLayer.getSource().clear();
        dispatch(resetUserCoordinates(map));
        setIsLocationSetManually(false);
      });

      const overlay = new Overlay({
        element: linkElement,
        position: fromLonLat([
          user.coordinates.longitude,
          user.coordinates.latitude,
        ]),
        positioning: "center-center",
        offset: [0, 50],
      });

      overlayRef.current = overlay;

      // Add the overlay to the map if it doesn't already exist
      const overlays = map.map.getOverlays().getArray();
      if (!overlays.includes(overlay)) {
        map.map.addOverlay(overlay);
      }
    }
  }, [isLocationSetManually]);

  useEffect(() => {
    if (user.isLocalized) {
      map.userPositionLayer.getSource().clear();
      let feature = new Feature({
        geometry: new Point(
          fromLonLat([user.coordinates.longitude, user.coordinates.latitude])
        ),
        label: identity.user.shortName
          ? `Jesteś tutaj`
          : false,
      });
      map.userPositionLayer.getSource().addFeature(feature);

      if (zoom < 17 && isCenterMyPositionEnabled === true) {
        map.map
          .getView()
          .setCenter(
            fromLonLat([user.coordinates.longitude, user.coordinates.latitude])
          );
      }
    }
    if (place.showNewPlaceForm === false && place.showPopup === false) {
      geolocation.on("change:position", handlePositionChange);
    }
    return () => {
      if (place.showNewPlaceForm === false && place.showPopup === false) {
        geolocation.un("change:position", handlePositionChange);
      }
    };
  }, [
    user.isLocalized,
    user.coordinates,
    place.showNewPlaceForm,
    identity.user.shortName,
    isCenterMyPositionEnabled,
  ]);

  useEffect(() => {
    map.map.on("singleclick", handleClick);
    map.map.on("singleclick", handleManualLocalizationClick);

    // Remove all features from the vector layer source
    map.placesLayer.getSource().clear();
    map.donatedPlacesLayer.getSource().clear();
    map.reusedPlacesLayer.getSource().clear();

    // Initialize an empty array to store the photo overlays
    let photoOverlays = [];

    places.forEach((doc) => {
      // Process the matching documents
      let coordinates = doc.get("coordinates");

      let placeLabel = (doc) => {
        let label = false;
        if (doc.get("comments").length > 0) {
          label = doc.get("label") + " 💬";
        } else {
          label = doc.get("label");
        }
        return label;
      };

      let feature = new Feature({
        geometry: new Point(
          fromLonLat([coordinates.longitude, coordinates.latitude])
        ),
        label: zoom > 17 ? placeLabel(doc) : false,
      });

      // Set the ID for the feature
      feature.setId(doc.id);

      // Add the photo as an overlay to the feature
      let photoUrl = doc.get("picture");

      if (photoUrl && zoom > 17) {
        // Check if there is already a photo overlay at this location
        let existingOverlay = photoOverlays.find((overlay) => {
          let existingPosition = overlay.getPosition();
          let newPosition = fromLonLat([
            coordinates.longitude,
            coordinates.latitude,
          ]);
          return (
            existingPosition[0] === newPosition[0] &&
            existingPosition[1] === newPosition[1]
          );
        });

        if (existingOverlay) {
          // If there is an existing overlay, update its image source
          let photoImage = existingOverlay
            .getElement()
            .getElementsByTagName("img")[0];
          photoImage.src = photoUrl;
        } else {
          // If there is no existing overlay, create a new one
          let photoOverlay = new Overlay({
            element: document.createElement("div"),
            position: fromLonLat([coordinates.longitude, coordinates.latitude]),
            positioning: [40, -50],
          });

          let photoImage = new Image();
          photoImage.src = photoUrl;
          photoImage.width = 100;
          photoImage.className = "shadow";
          photoImage.featureId = doc.id;
          photoImage.coordinates = coordinates;
          photoImage.addEventListener("click", handleClick);
          photoImage.onload = () => {
            // Append the photo image and icon to the overlay element
            photoOverlay.getElement().appendChild(photoImage);

            let supporters = doc.get("supporters") || [];

            if (supporters.length > 0) {
              // Create the icon element
              let icon = document.createElement("div");
              icon.className = "map-supporter-icon";
              photoOverlay.getElement().appendChild(icon);
            }

            // Add the new overlay to the map
            map.map.addOverlay(photoOverlay);
          };

          // Add the new overlay to the array
          photoOverlays.push(photoOverlay);
        }
      }

      // Remove all photo overlays if zoom is less than 17
      if (zoom <= 17) {
        photoOverlays.forEach((overlay) => {
          map.map.removeOverlay(overlay);
        });
        photoOverlays = [];
      }

      // Add the feature to the appropriate layer
      if (doc.get("donation")) {
        map.donatedPlacesLayer.getSource().addFeature(feature);
      } else if (doc.get("label") == "Wystawka") {
        map.reusedPlacesLayer.getSource().addFeature(feature);
      } else {
        if (showOnlyDonatedPlaces === false) {
          map.placesLayer.getSource().addFeature(feature);
        }
      }
    });

    return () => {
      map.map.un("singleclick", handleClick);
      map.map.un("singleclick", handleManualLocalizationClick);

      // Remove all photo overlays when the component unmounts
      photoOverlays.forEach((overlay) => {
        map.map.removeOverlay(overlay);
      });
    };
  }, [places, zoom, showOnlyDonatedPlaces, isLocationSetManually]);

  const jsonData = activities.map((activity, index) => {
    const avatarSrc = activity.get("object.identity.photoUrl");
    const shortName = activity.get("object.identity.shortName");
    const activityType = activity.get("activityType");
    const label = activity.get("label");
    const createdAt = activity.get("object.createdAt.localeString");
    const coordinates = activity.get("object.coordinates");

    return {
      id: `${activity.id}-${index}`,
      avatarSrc,
      shortName,
      activityType,
      label,
      createdAt,
      coordinates,
    };
  });

  const sortedData = jsonData.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  return (
    <>
      {contextHolder}
      {showWelcomePopup === true ? <WelcomePopup /> : ""}
      <div
        className="text-center"
        style={{
          overflowY: "scroll",
          bottom: "123px",
          position: "fixed",
          width: "100%",
          height: "160px",
          zIndex: "1000",
          background: "linear-gradient(rgb(255, 255, 255) 80%, #f0f0f0 100%)",
          paddingTop: "10px",
        }}
      >
        <div className="container mt-2">
          <div className="row align-items-center">
            {activities.length == 0 ? (
              <>
                <p className="text-white small mt-2 mb-2">
                  <img
                    style={{ maxWidth: "500px" }}
                    src="/img/preloader.png"
                    className="img-fluid"
                    onClick={() => fetchActivities(5)}
                  />
                </p>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(#f0f0f0 63%, rgba(96, 96, 96, 0) 100%)",
                  marginTop: "-18px",
                  height: "31px",
                  zIndex: "10000",
                  position: "sticky",
                  top: "-24px",
                }}
              ></div>
            )}
            {sortedData.map((data) => (
              <React.Fragment key={data.id}>
                <div
                  className="col-12 mb-2 small"
                  style={{ textAlign: "left", color: "#6c757d" }}
                >
                  <Space>
                    <Avatar src={data.avatarSrc}></Avatar> {data.shortName}
                    {data.activityType === "newComment" ? (
                      <CommentOutlined />
                    ) : (
                      ""
                    )}
                    {data.activityType === "placeCreated" ? (
                      <FlagOutlined />
                    ) : (
                      ""
                    )}
                    {data.activityType === "placeDeleted" ? (
                      <DeleteOutlined />
                    ) : (
                      ""
                    )}
                    {data.activityType === "supporterFixed" ? (
                      <HeartOutlined />
                    ) : (
                      ""
                    )}
                    {data.activityType === "newSupporter" ? (
                      <SmileOutlined />
                    ) : (
                      ""
                    )}
                    {data.activityType === "placeFixed" ? (
                      <CheckOutlined />
                    ) : (
                      ""
                    )}
                    {data.activityType === "supporterResigned" ? (
                      <MehOutlined />
                    ) : (
                      ""
                    )}{" "}
                    <a
                      href="#"
                      style={{ color: "#000000d9" }}
                      onClick={() => {
                        map.map.getView().setZoom(17.5);
                        setZoom(17.5);
                        const coordinates = data.coordinates;
                        map.map
                          .getView()
                          .setCenter(
                            fromLonLat([
                              coordinates.longitude,
                              coordinates.latitude,
                            ])
                          );
                      }}
                    >
                      {data.label}
                    </a>
                    <div>{data.createdAt}</div>
                  </Space>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div
        className="text-center"
        style={{
          bottom: "64px",
          position: "fixed",
          width: "100%",
          height: "85px",
          zIndex: "1000",
          background: "#f5f5f5",
          paddingTop: "10px",
        }}
      >
        <div
          className="text-center"
          style={{
            bottom: "295px",
            position: "fixed",
            zIndex: "1000",
            width: "100%",
          }}
        >
          <div
            className={`mb-2 ${
              mostActiveByPlaceCreated.length > 0 &&
              showMostActiveByPlaceCreated === true
                ? "d-block"
                : "d-none"
            }`}
          >
            <Tooltip
              title={`Aktywni w tej lokalizacji`}
              zIndex={1000}
              visible={
                mostActiveByPlaceCreated.length > 0 &&
                showMostActiveByPlaceCreated === true
              }
            >
              <Space direction="horizontal">
                {mostActiveByPlaceCreated.map((identity, index) => {
                  return (
                    <Badge key={index} count={identity.counter}>
                      <Avatar
                        key={identity.identity.id}
                        src={identity.identity.photoUrl}
                      />
                    </Badge>
                  );
                })}
              </Space>
            </Tooltip>
          </div>

          <Tooltip
            title={`Posprzątaliśmy tutaj już ${fixedPlacesCounter} miejsc!`}
            zIndex={1000}
            visible={fixedPlacesCounter > 0 && showFixedPlaceCounter}
          >
            <Button
              type="primary"
              onClick={showNextPlace}
              icon={<CompassOutlined />}
            >
              Odkryj, sprzątaj, przemieniaj otoczenie!
            </Button>
          </Tooltip>
        </div>

        <Tooltip
                    title={`Wybierz miejscowość lub wskaż klikiem na mapie. Włącz geolokalizację, a Odpado automatycznie oznaczy Twoją aktualną lokalizację.`}
                    zIndex={1000}
                    placement="top"
                    visible={
                      !user.isLocalized
                    }
                  >

        <Select
          showSearch
          placeholder="Wybierz miejscowość"
          onChange={onCityChange}
          filterOption={(input, option) =>
            option.label.toLowerCase().startsWith(input.toLowerCase())
          }
          style={{
            width: "90%",
          }}
          options={convertCitiesFormat(data)}
        />

        </Tooltip>

        <div className="container mt-2 px-0">
          <div className="row px-0 align-items-center">
            <div className="col-5 text-end px-0">
              <span className="text-uppercase small mr-2">
                <AntImage width={18} src="/img/user.png"></AntImage>{" "}
                <Switch
                  onChange={handleCenterMyPosition}
                  checked={isCenterMyPositionEnabled}
                />
              </span>
              <span className="text-uppercase small mr-2">
                <AntImage width={18} src="/img/trash-pln.png"></AntImage>{" "}
                <Switch onChange={handleDonatedPlacesFilter} />
              </span>
            </div>
            <div className="col-1 px-0 text-center">
              <Button
                size="small"
                style={{ marginLeft: "8px" }}
                onClick={handleFindMe}
                shape="circle"
                icon={<AimOutlined />}
              ></Button>
            </div>
            <div className="col-6 px-0">
                <Radio.Group
                  disabled={isSliderDisabled}
                  value={radius}
                  onChange={(e) => handleRadiusChange(e.target.value)}
                  size="small"
                >
                  <Radio.Button value="5">5km</Radio.Button>
                  <Radio.Button value="10">10km</Radio.Button>
                  <Radio.Button value="15">15km</Radio.Button>
                </Radio.Group>
            </div>
          </div>
        </div>
      </div>

      <PlacePopup></PlacePopup>
      <Spin spinning={isSliderDisabled} delay={500}>
        <div id="map" className="ol-map"></div>
      </Spin>
    </>
  );
};

const CustomMap = () => {
  const identity = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const [isSettingsPopupSeen, setIsSettingsPopupSeen] = useState(true);

  if (!identity.isLogged) {
    return null;
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  onMessage(messaging, (payload) => {
    // Extract relevant information from the payload
    const { title, subtitle, body } = payload.notification;

    // Additional code for notification handling
    addNotification({
      title,
      subtitle,
      message: body,
      theme: "darkblue",
      native: true, // When using native, your OS will handle theming.
      icon: "https://odpado.pl/img/smiecapka.png",
    });
  });

  return (
    <>
      <Map />
      {user.isLocalized === false && isSettingsPopupSeen === false && (
        <>
          <SettingsPopup />
        </>
      )}
    </>
  );
};

export default CustomMap;
