import React, { Component } from "react";
import { Card, Alert, Space, Image, Divider } from "antd";
import { CompassOutlined, AimOutlined } from "@ant-design/icons";

class About extends Component {
  render() {
    return (
      <div className="container p-4 py-5 my-5">
        <div className="row">
          <div className="col py-4">
            <Space direction="vertical" size={15} style={{ display: "flex" }}>
              <Card className="mt-4">
                <Image src="/img/head.png"></Image>

                <Space size={30}>
                  Strona aplikacji: <a href="https://odpado.pl/aplikacja">https://odpado.pl/aplikacja</a>
                </Space>

                <h5 className="mt-2 mb-4">Znaczenie wybranych ikon i funkcji w aplikacji</h5>
                
                <div className="container">
                <Space direction="vertical">
                <div className="row">
                    <div className="col-2">
                      <img src="/img/recycle-bag.png" className="img-fluid" />
                    </div>
                    <div className="col-10">
                      Ikona zielonej torby sygnalizuje miejsce, w którym ktoś pozostawił przydatne do ponownego użycia rzeczy tzw. "wystawka".
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <img src="/img/trash.png" className="img-fluid" />
                    </div>
                    <div className="col-10">
                      Ikona żółtej flagi sygnalizuje miejsce wymagające
                      sprzątania.
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <img src="/img/trash-pln.png" className="img-fluid" />
                    </div>
                    <div className="col-10">
                    Ikona żółtej flagi z znaczkiem PLN oznacza miejsce, które potrzebuje uprzątnięcia, a za to zadanie przewidziane jest wynagrodzenie. Czeka na Ciebie okazja do zarobku i pomocy jednocześnie!
                    </div>
                  </div>   
                  <div className="row">
                    <div className="col-2">
                      <img src="/img/77795-200.png" className="img-fluid" />
                    </div>
                    <div className="col-10">
                    Ikonka ludzika z workiem śmieci symbolizuje, że miejsce posiada chętnych do pomocy lub kogoś, kto już wsparł je swoją pomocą. Oznacza to, że miejsce ma wsparcie i że można dołączyć do innych osób, które już wzięły udział w akcji sprzątania lub zadeklarowały swoją gotowość do pomocy. To zachęta dla innych, aby dołączyli i wspólnie przyczynili się do utrzymania czystości i poprawy jakości naszego otoczenia.
                    </div>
                  </div>                     
                  <div className="row">
                    <div className="col-2">
                      <img src="/img/user.png" className="img-fluid" />
                    </div>
                    <div className="col-10">
                    Ikonka ludzika oznacza Twoją pozycję na mapie. Przesuwając suwak w menu, możesz ustawić, aby ludzik zawsze znajdował się w centrum mapy. Jest to szczególnie przydatne podczas marszu w kierunku celu, abyś mógł śledzić swoje położenie na bieżąco.
                    </div>
                  </div>        
                  <div className="row">
                    <div className="col-2">
                    <AimOutlined />  
                    </div>
                    <div className="col-10">
                    Kilknięcie w ikonkę centrowania na mapie spowoduje zaktualizowanie Twojej pozycji na mapie do bieżącego położenia. To pozwoli Ci śledzić swoje aktualne miejsce na mapie i być zawsze dobrze zorientowanym w otoczeniu.
                    </div>
                  </div>        
                  <div className="row">
                    <div className="col-2">
                    <CompassOutlined />  
                    </div>
                    <div className="col-10">
                    Funkcja 'Odkryj, sprzątaj, przemieniaj otoczenie!' jest jedną z kluczowych funkcji śmieciappki. Pozwala ona użytkownikom odkrywać miejsca, które wymagają posprzątania i stanowią okazję do działania. Ta funkcja ma na celu inspirację i motywację do czynnego uczestnictwa w poprawianiu otoczenia poprzez sprzątanie. Zachęca do podejmowania akcji i przyczynia się do pozytywnych zmian w naszym środowisku.
                    </div>
                  </div> 
                             
                  </Space>               
                </div>
                <p></p>
                <Divider></Divider>
                <p>
                  Odpado to aplikacja społecznościowa, która umożliwia
                  dbanie o porządek w swojej okolicy w sposób interaktywny i
                  atrakcyjny. Dzięki intuicyjnej mapie, którą każdy może
                  edytować, łatwo oznaczyć miejsca zanieczyszczone śmieciami i
                  informować o nich innych użytkowników. To świetny sposób, by
                  razem zadbać o czystość i estetykę naszego otoczenia.
                </p>
                <p>
                  Ale to nie wszystko! W ramach naszej aplikacji oferujemy także
                  możliwość zarabiania dla chętnych do posprzątania oznaczonych
                  miejsc.
                </p>
                <p>
                  Przynależność do naszej społeczności to także doskonały sposób
                  na budowanie relacji z innymi osobami, które dbają o swoją
                  okolicę. Dzięki możliwości komunikowania się z innymi
                  użytkownikami naszej aplikacji możesz wymieniać się
                  informacjami, sugestiami i pomysłami na dalsze działania, by w
                  sposób ciągły dbać o czystość i estetykę naszej przestrzeni.
                </p>
              </Card>

              <Alert
                message="Masz pomysł na współpracę?"
                description="Skontaktuj się z twórcą Odpado pod numerem telefonu 730 775 333 lub pod adresem kontakt@michalszafranski.pl"
                type="info"
                showIcon
              />
            </Space>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
