import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import Icon from "ol/style/Icon";
import Text from "ol/style/Text";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import Feature from "ol/Feature";

export default class MapFactory {
    static instance = null;

    /**
     * 
     */
    constructor() {
        if (MapFactory.instance) {
            return MapFactory.instance;
        }
        this.placesLayer = null;
        this.donatedPlacesLayer = null;
        this.reusedPlacesLayer = null;
        this.userPositionLayer = null;
        this.accuracyFeature = null;
        this.positionFeature = null;
        this.map = null;
        MapFactory.instance = this;
    }

    /**
     * 
     * @returns 
     */
    initialize(coordinates) {
        let map = new Map({
            view: new View({
                center: fromLonLat(coordinates),
                zoom: 15,
            }),
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                this.composePlacesLayer(),
                this.composeUserPositionLayer(),
                this.composeDonatedPlacesLayer(),
                this.composeReusedPlacesLayer()
            ],
            target: "map",
            loadTilesWhileAnimating: true,
            controls: [],
        });

        map.on("click", (evt) => {
            const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature;
            });
            if (feature) {

            }
        });

        this.map = map;
        return this;
    }


    /**
     * 
     * @returns 
     */
    composeAccuracyFeature() {
        if (this.accuracyFeature != null) {
            return this.accuracyFeature;
        }
        return this.accuracyFeature = new Feature();
    }

    /**
     * 
     * @param {*} vectorSource 
     * @returns 
     */
    composePlacesLayer() {
        if (this.placesLayer != null) {
            return this.placesLayer;
        }
        return this.placesLayer = new VectorLayer({
            source: new VectorSource(),
            style: function (feature) {
                let iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.1, 1.2],
                        anchorXUnits: "fraction",
                        anchorYUnits: "fraction",
                        src: "/img/trash.png"
                    }),
                });

                var labelStyle = new Style({
                    text: new Text({
                        font: '14px Roboto',
                        overflow: true,
                        fill: new Fill({
                            color: '#000'
                        }),
                        backgroundFill: new Fill({
                            color: '#fff'
                        }),
                        padding: [4, 4, 4, 4],
                        offsetY: -89,
                        offsetX: 69,
                        text: feature.get('label'),
                        backgroundStroke: new Stroke({
                            color: '#000',
                            width: 2,
                            lineCap: 'round',
                            lineJoin: 'round'
                        })
                    })
                });


                return [iconStyle, labelStyle];
            },
            updateWhileAnimating: true,
            updateWhileInteracting: true,
        });
    }

    /**
 * 
 * @param {*} vectorSource 
 * @returns 
 */
    composeUserPositionLayer() {
        if (this.userPositionLayer != null) {
            return this.userPositionLayer;
        }
        return this.userPositionLayer = new VectorLayer({
            source: new VectorSource(),
            style: function (feature) {
                let iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 0.5],
                        anchorXUnits: "fraction",
                        anchorYUnits: "fraction",
                        src: "/img/user.png"
                    }),
                });

                var labelStyle = new Style({
                    text: new Text({
                        font: '15px Roboto',
                        overflow: true,
                        fill: new Fill({
                            color: '#000'
                        }),
                        backgroundFill: new Fill({
                            color: '#fff'
                        }),
                        padding: [6, 6, 6, 6],
                        offsetY: -55,
                        text: feature.get('label'),
                        backgroundStroke: new Stroke({
                            color: '#000',
                            width: 3,
                            lineCap: 'round',
                            lineJoin: 'round'
                        })
                    })
                });

                return [iconStyle, labelStyle];
            },
            updateWhileAnimating: true,
            updateWhileInteracting: true,
        });
    }


    composeDonatedPlacesLayer() {
        if (this.donatedPlacesLayer != null) {
            return this.donatedPlacesLayer;
        }
        return this.donatedPlacesLayer = new VectorLayer({
            source: new VectorSource(),
            style: function (feature) {
                let iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 0.5],
                        anchorXUnits: "fraction",
                        anchorYUnits: "fraction",
                        src: "/img/trash-pln.png"
                    }),
                });

                var labelStyle = new Style({
                    text: new Text({
                        font: '14px Roboto',
                        overflow: true,
                        fill: new Fill({
                            color: '#000'
                        }),
                        backgroundFill: new Fill({
                            color: '#fff'
                        }),
                        padding: [4, 4, 4, 4],
                        offsetY: -49,
                        text: feature.get('label'),
                        backgroundStroke: new Stroke({
                            color: '#000',
                            width: 2,
                            lineCap: 'round',
                            lineJoin: 'round'
                        })
                    })
                });

                return [iconStyle, labelStyle];
            },
            updateWhileAnimating: true,
            updateWhileInteracting: true,
        });
    }

    composeReusedPlacesLayer() {
        if (this.reusedPlacesLayer != null) {
            return this.reusedPlacesLayer;
        }
        return this.reusedPlacesLayer = new VectorLayer({
            source: new VectorSource(),
            style: function (feature) {
                let iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 0.5],
                        anchorXUnits: "fraction",
                        anchorYUnits: "fraction",
                        src: "/img/recycle-bag.png"
                    }),
                });

                var labelStyle = new Style({
                    text: new Text({
                        font: '14px Roboto',
                        overflow: true,
                        fill: new Fill({
                            color: '#000'
                        }),
                        backgroundFill: new Fill({
                            color: '#fff'
                        }),
                        padding: [4, 4, 4, 4],
                        offsetY: -49,
                        text: feature.get('label'),
                        backgroundStroke: new Stroke({
                            color: '#000',
                            width: 2,
                            lineCap: 'round',
                            lineJoin: 'round'
                        })
                    })
                });

                return [iconStyle, labelStyle];
            },
            updateWhileAnimating: true,
            updateWhileInteracting: true,
        });
    }

    /**
   * 
   * @returns 
   */
    composePositionFeature() {
        let positionFeature = new Feature();
        positionFeature.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 0.15,
                    fill: new Fill({
                        color: "#3399CC",
                    }),
                    stroke: new Stroke({
                        color: "#fff",
                        width: 2,
                    }),
                }),
            })
        );
        return positionFeature;
    }

    /**
     * 
     * @returns 
     */
    composeVectorSource() {
        return this.vectorSource = new VectorSource({
            features: [
                this.composeAccuracyFeature(),
                this.composePositionFeature()
            ]
        });
    }
}