import { Modal, Avatar, Input, Radio, Space, Button, Card, Select, message } from 'antd';
import { fetchOpenStreetPlaceData } from "../../libs/Geolocation/OpenStreet";
import React, { useState, useEffect } from 'react';
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs, addDoc, deleteDoc } from "firebase/firestore";
import {
    MailOutlined
} from '@ant-design/icons';
import { useSelector } from "react-redux";

const MailingListPopup = () => {
    const user = useSelector((state) => state.user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userAction, setUserAction] = useState(1);
    const [addressEmail, setAddressEmail] = useState("");
    const [areas, setAreas] = useState([]);
    const [areaOfInterests, setAreaOfInterests] = useState(false);
    const [locationName, setLocationName] = useState("");
    const [messageApi, contextHolder] = message.useMessage();

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);

    useEffect(() => {

        if (isModalOpen) {

            fetchOpenStreetPlaceData(user.coordinates).then((data) => {

                let areas = new Array;
                let prefix = new Array;
                var mailingListPrefix = "";
                var locationName = "";

                if (data.features[0].properties.address.municipality) {

                    mailingListPrefix = "municipality_" + data.features[0].properties.address.municipality.replace("gmina ", "");
                    prefix.push(mailingListPrefix);
                    locationName = data.features[0].properties.address.municipality.replace("gmina ", "");

                    areas.push({
                        value: replacePolishLetters(mailingListPrefix),
                        label: data.features[0].properties.address.municipality.replace("gmina", "Gmina:")
                    });

                }

                if (data.features[0].properties.address.city) {

                    mailingListPrefix = "city_" + data.features[0].properties.address.city;
                    prefix.push(mailingListPrefix);
                    locationName = data.features[0].properties.address.city;

                    areas.push({
                        value: replacePolishLetters(prefix.join("_")),
                        label: "Miasto: " + data.features[0].properties.address.city
                    });

                }

                if (data.features[0].properties.address.town) {

                    mailingListPrefix = "town_" + data.features[0].properties.address.town;
                    prefix.push(mailingListPrefix);
                    locationName = data.features[0].properties.address.town;

                    areas.push({
                        value: replacePolishLetters(prefix.join("_")),
                        label: "Miasto: " + data.features[0].properties.address.town
                    });
                }

                if (data.features[0].properties.address.city_district) {

                    mailingListPrefix = "city_district_" + data.features[0].properties.address.city_district;
                    prefix.push(mailingListPrefix);

                    areas.push({
                        value: replacePolishLetters(prefix.join("_")),
                        label: "Dzielnica miasta: " + data.features[0].properties.address.city_district
                    });

                }

                if (data.features[0].properties.address.suburb) {

                    mailingListPrefix = "suburb_" + data.features[0].properties.address.suburb;
                    prefix.push(mailingListPrefix);

                    areas.push({
                        value: replacePolishLetters(prefix.join("_")),
                        label: "Dzielnica: " + data.features[0].properties.address.suburb
                    });

                }

                if (data.features[0].properties.address.quarter) {

                    mailingListPrefix = "quarter_" + data.features[0].properties.address.quarter;
                    prefix.push(mailingListPrefix);

                    areas.push({
                        value: replacePolishLetters(prefix.join("_")),
                        label: "Dzielnica: " + data.features[0].properties.address.quarter
                    });
                }

                if (data.features[0].properties.address.village) {

                    mailingListPrefix = "village_" + data.features[0].properties.address.village;
                    prefix.push(mailingListPrefix);
                    locationName = data.features[0].properties.address.village;

                    areas.push({
                        value: replacePolishLetters(prefix.join("_")),
                        label: "Miejscowość: " + data.features[0].properties.address.village
                    });
                }

                if (data.features[0].properties.address.road) {

                    mailingListPrefix = "road_" + data.features[0].properties.address.road;
                    prefix.push(mailingListPrefix);

                    areas.push({
                        value: replacePolishLetters(prefix.join("_")),
                        label: "Ulica: " + data.features[0].properties.address.road
                    });
                }

                setAreas(areas);
                setLocationName(locationName);
            });
        }

    }, [user.coordinates, isModalOpen]);

    /**
     * 
     * @param {*} e 
     */
    const onUserActionChange = (e) => {
        setUserAction(e.target.value);
    };


    /**
     * 
     * @param {*} e 
     */
    const onAddressEmailChange = (e) => {
        setAddressEmail(e.target.value);
    };

    /**
     * 
     * @param {*} value 
     */
    const onAreaChange = (value) => {
        setAreaOfInterests(value);

    };

    /**
     * 
     */
    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    /**
     * 
     */
    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    /**
     * 
     */
    const isEmailValid = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailRegex.test(email);
    };

    /**
     * 
     */
    const handleSubscribeUnsubscribe = () => {

        if (!isEmailValid(addressEmail)) {
            messageApi.open({
                type: "error",
                content: "Adres e-mail jest niepoprawny.",
            });
            return;
        }

        if (!areaOfInterests) {
            messageApi.open({
                type: "error",
                content: "Wybierz obszar, którym jesteś zainteresowany.",
            });
            return;
        }

        if (userAction == 1) {
            // Add a new document in collection "places"
            addEmailIfNotExists(areaOfInterests, addressEmail);
        } else {
            removeEmailIfExists(areaOfInterests, addressEmail);
        }

    }

    /**
     * 
     * Function to add email to collection if it doesn't already exist
     * 
     * @param {*} areaOfInterests 
     * @param {*} addressEmail 
     */
    async function addEmailIfNotExists(areaOfInterests, addressEmail) {
        // Query the collection to check if email exists
        const q = query(collection(db, "activity_email_subscribers_" + areaOfInterests), where("email", "==", addressEmail));
        const querySnapshot = await getDocs(q);

        // If email doesn't exist, add it to the collection
        if (querySnapshot.empty) {
            // Add a new document in collection "places"
            await addDoc(collection(db, "activity_email_subscribers_" + areaOfInterests), {
                docStructVersion: "1.0.0",
                email: addressEmail,
                createdAt: {
                    timestamp: Date.now(),
                    localeString: new Date().toLocaleString(),
                },
            });
            messageApi.open({
                type: "success",
                content: "Adres e-mail został dopisany do lokalizacji.",
            });

            setUserAction(1);
            setAddressEmail("");
            setAreaOfInterests(false);
            setIsModalOpen(false);
        } else {
            messageApi.open({
                type: "error",
                content: "Podany adres e-mail już istnieje.",
            });
        }
    }

    /**
     * 
     * Function to remove email from collection if it exists
     * 
     * @param {*} areaOfInterests 
     * @param {*} addressEmail 
     */
    async function removeEmailIfExists(areaOfInterests, addressEmail) {
        // Query the collection to check if email exists
        const q = query(collection(db, "activity_email_subscribers_" + areaOfInterests), where("email", "==", addressEmail));
        const querySnapshot = await getDocs(q);

        // If email exists, remove it from the collection
        if (!querySnapshot.empty) {
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });
            messageApi.open({
                type: "success",
                content: "Adres e-mail został usunięty z lokalizacji.",
            });

            setUserAction(1);
            setAddressEmail("");
            setAreaOfInterests(false);
            setIsModalOpen(false);
        } else {
            messageApi.open({
                type: "error",
                content: "Podany adres e-mail nie istnieje.",
            });
        }
    }

    /**
     * 
     * @param {*} string 
     * @returns 
     */
    const replacePolishLetters = (string) => {

        // Replace Polish characters with their English counterparts
        var string = string.replace(/[ĄĆĘŁŃÓŚŹŻąćęłńóśźż]/g, function (match) {
            switch (match) {
                case 'Ą': return 'A';
                case 'ą': return 'a';
                case 'Ć': return 'C';
                case 'ć': return 'c';
                case 'Ę': return 'E';
                case 'ę': return 'e';
                case 'Ł': return 'L';
                case 'ł': return 'l';
                case 'Ń': return 'N';
                case 'ń': return 'n';
                case 'Ó': return 'O';
                case 'ó': return 'o';
                case 'Ś': return 'S';
                case 'ś': return 's';
                case 'Ź': return 'Z';
                case 'ź': return 'z';
                case 'Ż': return 'Z';
                case 'ż': return 'z';
                default: return match;
            }
        });

        return string.replace(/ /g, '_').replace(/-/g, '_').toLowerCase();
    }

    return (

        <>
            {contextHolder}
            <div onClick={handleModalOpen}>
                <Avatar className='shadow' style={{ backgroundColor: '#fa8c16' }} icon={<MailOutlined style={{ display: "block", marginTop: "11px" }} />} size={40} />
            </div>

            <Modal
                visible={isModalOpen}
                footer={null}
                closable={true}
                centered={true}
                onCancel={handleModalClose}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <img src="/img/email_list.png" className="img-fluid" />
                        </div>
                        <div className="col-sm-12 mt-4">
                            <Space direction='vertical'>
                                <h1>Włącz powiadomienia dotyczące Twoich zgłoszeń oraz dyskusji społeczności {locationName} prosto na Twoją skrzynkę e-mail.</h1>
                                <Card>
                                    <Space size={20} direction='vertical'>
                                        <Input onChange={onAddressEmailChange} placeholder="Twój adres e-mail" />
                                        <Select placeholder="Wybierz obszar, który Ciebie interesuje" onChange={onAreaChange} style={{ width: 250 }} options={areas} />
                                        <Radio.Group onChange={onUserActionChange} value={userAction}>
                                            <Radio value={1}>Dodaj do powiadomień</Radio>
                                            <Radio value={2}>Usuń z powiadomień</Radio>
                                        </Radio.Group>
                                        <Button onClick={handleSubscribeUnsubscribe} type="primary">Zapisz</Button>
                                    </Space>
                                </Card>
                                <p>
                                    Otrzymasz powiadomienia o nowych aktywnościach. To może być komentarz dodany do zgłoszonego przez Ciebie miejsca lub informacja o posprzątaniu tego miejsca. Nie będziesz otrzymywać żadnych ofert, newsletterów ani innych informacji niezwiązanych ze zgłoszeniami.
                                </p>
                            </Space>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default MailingListPopup;
