import React, { Component } from "react";
import { Card, Alert, Space, message, List, Avatar, Image, Tabs } from "antd";

import { initializeApp } from "firebase/app";
import { geohashQueryBounds, distanceBetween } from "geofire-common";
import { MessageOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import firebaseConfig from "../../config";
import {
  getFirestore,
  query,
  orderBy,
  startAt,
  endAt,
  collection,
  getDocs,
  limit,
  where,
  doc,
  getDoc,
} from "firebase/firestore";

import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const Activity = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const identity = useSelector((state) => state.auth.user);
  const user = useSelector((state) => state.user);
  const [places, setPlaces] = useState([]);
  const [cityPlaces, setCityPlaces] = useState([]);
  const [isFetchPlacesCalled, setIsFetchPlacesCalled] = useState(false);
  const [me, setMe] = useState(false);
  const { TabPane } = Tabs;
  /**
   *
   */
  let fetchPlaces = async () => {
    setIsFetchPlacesCalled(true);
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const placesQuery = await getDocs(
      query(
        collection(db, "places"),
        where("identity.id", "==", identity.id),
        orderBy("createdAt.timestamp", "desc"),
        limit(50)
      )
    );

    const places = [];
    placesQuery.forEach((doc) => {
      const place = doc.data();
      places.push({
        title: place.label,
        picture: place.picture,
        tags: place.tags,
        createdAt: place.createdAt.localeString,
        commentsCounter: place.comments.length,
        isDonated: place.donation > 0,
        isFixed: place.status === "fixed",
        coordinates: place.coordinates,
      });
    });

    // do something with the places array, e.g. render them in a list
    setPlaces(places);

    const userRef = doc(db, "users", identity.id);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const user = userSnap.data();
      setMe(user);
      const fcmSubscribedAreas = user.fcmSubscribedAreas || [];
      if (fcmSubscribedAreas.length > 0) {
        const cityPlaces = [];

        for (const area of fcmSubscribedAreas) {
          const center = [
            area.coordinates.latitude,
            area.coordinates.longitude,
          ];
          const radiusInM = area.radius;
          const bounds = geohashQueryBounds(center, radiusInM);
          let q = "";

          for (const b of bounds) {
            q = query(
              collection(db, "places"),
              where("status", "==", "new"),
              orderBy("geohash"),
              limit(100),
              startAt(b[0]),
              endAt(b[1])
            );
            const cityPlacesQuery = await getDocs(q);

            cityPlacesQuery.forEach((doc) => {
              const place = doc.data();
              cityPlaces.push({
                identity: place.identity,
                picture: place.picture,
                title: place.label,
                tags: place.tags,
                createdAt: place.createdAt.localeString,
                commentsCounter: place.comments.length,
                isDonated: place.donation > 0,
                isFixed: place.status === "fixed",
                coordinates: place.coordinates,
              });
            });
          }
        }

        setCityPlaces(cityPlaces);
      }
    }

    messageApi.open({
      type: "success",
      content: `${places.length} miejsca, które dodałeś zostały pobrane.`,
    });
  };

  useEffect(() => {
    if (identity.id && isFetchPlacesCalled === false) {
      fetchPlaces();
    }
  });

  return (
    <>
      {contextHolder}
      <div className="container py-5 my-5">
        <div className="row">
          <div className="col py-4">
            <Card
              title={`Lista miejsc oflagowanych przez Ciebie oraz Społeczność`}
            >
              <Alert
                type="info"
                showIcon={true}
                message="Pokazujemy do 50 ostatnio dodanych miejsc. Pamiętaj, że uprzątnięte miejsca nie są widoczne na mapie. To samo dotyczy odległych miejsc, które dodasz spoza zasięgu 15 km od Twojej obecnej lokalizacji, a to dlatego, że Odpado korzysta z geolokalizacji, aby pokazać Ci okolicę."
              />

              <Tabs defaultActiveKey="1">
                <TabPane tab="Nowe" key="1">
                  <List
                    itemLayout="horizontal"
                    dataSource={places.filter((item) => !item.isFixed)}
                    renderItem={(item, index) => (
                      <div className="item-row mb-2 border-bottom">
                        <div className="item-column">
                          <a
                            href={`/?coords=${item.coordinates.latitude},${item.coordinates.longitude}`}
                          >
                            <Image className="mb-2" src={item.picture} />{" "}
                            {item.title}
                          </a>
                          {item.isDonated ? (
                            <Image width={30} src="/img/trash-pln.png" />
                          ) : (
                            <Image width={30} src="/img/trash.png" />
                          )}{" "}
                          {item.isFixed ? (
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                          ) : null}
                        </div>
                        <div className="item-column">
                          <div className="container-fluid m-0 p-0 mb-2 text-muted small">
                            <div className="row">
                              <div className="col-9">{item.createdAt} </div>
                              <div className="col-3 text-end">
                                <MessageOutlined /> {item.commentsCounter}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </TabPane>

                <TabPane tab="Załatwione" key="2">
                  <List
                    itemLayout="horizontal"
                    dataSource={places.filter((item) => item.isFixed)}
                    renderItem={(item, index) => (
                      <div className="item-row mb-2 border-bottom">
                        <div className="item-column">
                          <a
                            href={`/?coords=${item.coordinates.latitude},${item.coordinates.longitude}`}
                          >
                            <Image className="mb-2" src={item.picture} />{" "}
                            {item.title}
                          </a>
                          {item.isDonated ? (
                            <Image width={30} src="/img/trash-pln.png" />
                          ) : (
                            <Image width={30} src="/img/trash.png" />
                          )}{" "}
                          {item.isFixed ? (
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                          ) : null}
                        </div>
                        <div className="item-column">
                          <div className="container-fluid m-0 p-0 mb-2 text-muted small">
                            <div className="row">
                              <div className="col-9">{item.createdAt} </div>
                              <div className="col-3 text-end">
                                <MessageOutlined /> {item.commentsCounter}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </TabPane>
                <TabPane tab="Oflagowane przez społeczność" key="3">
                  {me.isPremium ? (
                    <List
                      itemLayout="horizontal"
                      dataSource={cityPlaces
                        .sort((a, b) => b.createdAt.localeCompare(a.createdAt)) // Sort by createdAt in descending order
                        .filter(
                          (item) =>
                            !item.isFixed && item.identity.id !== identity.id
                        )}
                      renderItem={(item, index) => (
                        <div className="item-row mb-2 border-bottom">
                          <div className="item-column">
                            <a
                              href={`/?coords=${item.coordinates.latitude},${item.coordinates.longitude}`}
                            >
                              <Image className="mb-2" src={item.picture} />{" "}
                              {item.title}
                            </a>
                            {item.isDonated ? (
                              <Image width={30} src="/img/trash-pln.png" />
                            ) : (
                              <Image width={30} src="/img/trash.png" />
                            )}{" "}
                            {item.isFixed ? (
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            ) : null}
                          </div>
                          <div className="item-column">
                            <div className="container-fluid m-0 p-0 mb-2 text-muted small">
                              <div className="row">
                                <div className="col-9">
                                  <Avatar src={item.identity.photoUrl}></Avatar>{" "}
                                  {item.identity.shortName}, {item.createdAt}
                                </div>
                                <div className="col-3 text-end">
                                  <MessageOutlined /> {item.commentsCounter}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  ) : (
                    <Alert
                      message="Tylko posiadacze konta Premium mają dostęp do listy aktywności w subskrybowanych obszarach. Ta funkcja została głównie stworzona z myślą o firmach zajmujących się porządkiem. Użytkownicy wersji darmowej mogą jednak przeglądać nowe miejsca bezpośrednio na mapie."
                      type="info"
                      showIcon
                    />
                  )}
                </TabPane>
              </Tabs>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activity;
