import React from "react";
import { getAuth, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../config";
import { message, Button } from "antd";
import { useDispatch } from "react-redux";
import { setUserLogged } from "../../reducers/features/auth/auth";
import { FacebookOutlined } from "@ant-design/icons";
import {
  getFirestore,
  addDoc,
  where,
  getDocs,
  collection,
  query,
  getDoc,
} from "firebase/firestore";
import { useState, useEffect } from "react";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new FacebookAuthProvider();

const saveIdentityObjectOntoLocalStorage = (identityObject) => {
  let identity = {
    displayName: identityObject.displayName,
    shortName: identityObject.shortName,
    id: identityObject.id,
    photoUrl: identityObject.photoUrl,
    fcmSubscribedPlaces: identityObject.fcmSubscribedPlaces,
  };
  localStorage.setItem("identity", JSON.stringify(identity));
};

const handleSignIn = async (dispatch, messageApi, setIsProcessing) => {
  try {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);

    // Check if user with email already exists
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      where("identity.email", "==", 'smieciappka@gmail.com')
    );
    getDocs(q)
      .then((snapshot) => {
          let doc = snapshot.docs[0].data();
          let identityObject = {
            displayName: doc.identity.displayName,
            shortName: doc.identity.shortName,
            id: snapshot.docs[0].id,
            photoUrl: doc.identity.photoUrl,
            fcmSubscribedPlaces: doc.fcmSubscribedPlaces,
          };
          saveIdentityObjectOntoLocalStorage(identityObject);
          dispatch(setUserLogged(identityObject));
          setIsProcessing(false);
      })
      .catch((error) => {
        //console.error("Error checking if user exists: ", error);
      });
  } catch (error) {
    let errorLabel = `Wystąpiły błędy podczas logowania: ${error}.`;

    if (
      typeof error === "object" &&
      error.message &&
      error.message.includes("auth/popup-closed-by-user")
    ) {
      const errorLabel =
        "Błąd logowania: Okno logowania Facebook zostało zamknięte. Rozpocznij jeszcze raz.";
      messageApi.open({
        type: "error",
        content: errorLabel,
      });

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } else if (
      typeof error === "object" &&
      error.message &&
      error.message.includes("auth/user-cancelled")
    ) {
      const errorLabel =
        "Błąd logowania: Aplikacja nie ma dostępu do adresu e-mail z Facebook. Rozpocznij jeszcze raz.";
      messageApi.open({
        type: "error",
        content: errorLabel,
      });

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } else {
      messageApi.open({
        type: "error",
        content: errorLabel,
      });
    }
  }
};

const AnonymousLoginButton = () => {
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSignInWithMessage = () => {
    setIsProcessing(true);
    handleSignIn(dispatch, messageApi, setIsProcessing);
  };

  return (
    <>
      {contextHolder}
      <Button
        type="primary"
        onClick={handleSignInWithMessage}
        disabled={isProcessing}
        size="large"
        style={{width: "100%"}}
      >
        Zacznij korzystać
      </Button>
    </>
  );
};

export default AnonymousLoginButton;
