const firebaseConfig = {
    apiKey: "AIzaSyD0ciSH4MnEQGahOxWEyoWLZ0XGUTsOOrY",
    authDomain: "trashcan-6e2b8.firebaseapp.com",
    databaseURL: "https://trashcan-6e2b8-default-rtdb.firebaseio.com",
    projectId: "trashcan-6e2b8",
    storageBucket: "trashcan-6e2b8.appspot.com",
    messagingSenderId: "1073969805048",
    appId: "1:1073969805048:web:fa0e2ea7d8ccaeda846d0d",
    measurementId: "G-LKMX57H55K"
  };
  
  export default firebaseConfig;