const initialState = {
    coordinates: { longitude: 0, latitude: 0 },
    map: null,
    isLocalized: false
};

const ACTIONS = {
    SET_USER_COORDINATES: 'userCoordinates',
    RESET_USER_COORDINATES: 'resetUserCoordinates'
};

export const setUserCoordinates = (userCoordinates, mapInstance) => {
    return {
        type: ACTIONS.SET_USER_COORDINATES, 
        payload: {
            coordinates: { 
                longitude: parseFloat(userCoordinates[0]),
                latitude: parseFloat(userCoordinates[1])
            },
            map: mapInstance,
            isLocalized: true
        }
      };
}

export const resetUserCoordinates = (mapInstance) => {
    return {
        type: ACTIONS.SET_USER_COORDINATES, 
        payload: {
            coordinates: { 
                longitude: 0,
                latitude: 0
            },
            map: mapInstance,
            isLocalized: false
        }
      };
}

export const setUserLogged = (user) => {
    return {
        type: ACTIONS.SET_USER_LOGGED, 
        payload: {
            isLogged: true,
            user: user
        }
      };
}

export default function filtersReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.SET_USER_COORDINATES: {
            return {
                ...state,
                coordinates: action.payload.coordinates,
                map: action.payload.map,
                isLocalized: action.payload.isLocalized,
                isLogged: action.payload.isLogged,
                user: action.payload.user
            };
        }; 
        case ACTIONS.RESET_USER_COORDINATES: {
            return {
                ...state,
                coordinates: action.payload.coordinates,
                map: action.payload.map,
                isLocalized: action.payload.isLocalized,
                isLogged: action.payload.isLogged,
                user: action.payload.user
            };
        };         
        default:
            return state;
    }
}